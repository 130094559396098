import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/NewPagination';
import { Search } from 'Components/Common/Search';
import { CreateNewOperatorDialog } from './OperatorDialog';
import { fetchSelectedOperators } from 'slices/client-operators/thunk';
import { selectedOperatorsDataSelector } from 'selectors/SelectedOperatorsSelectors';
import { DeleteOperatorDialog } from './DeleteOperatorDialog';
import { useProfile } from 'Components/Hooks/UserHooks';
import { PageTitle, PageTitleProps } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { Label } from '../../../Components/Common/Label';
import { userRelatedDataSelector } from '../../../selectors/accountSelectors';

export const Operators = () => {
    document.title = 'Operators';
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const [isCreateNewOperatorDialogOpen, setIsCreateNewOperatorDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isFromEdit, setIsFromEdit] = useState<boolean>(false);
    const [selectedOperator, setSelectedOperator] = useState<any>(null);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const { data, isInProgress, pagination } = useSelector(selectedOperatorsDataSelector);
    const { package: userPackage } = useSelector(userRelatedDataSelector);
    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 100,
        searchTerm: '',
        providerId: user?.isAdmin ? 0 : user?.providerId,
    });

    const search = (searchTerm: string) => {
        setFilters({ ...filters, page: 1, searchTerm });
    };

    const openEditDialog = (operator: any): void => {
        setIsFromEdit(true);
        setIsCreateNewOperatorDialogOpen(true);
        setSelectedOperator(operator);
    };
    const openDeleteDialog = (operator: any): void => {
        setDeleteDialogOpen(true);
        setSelectedOperator(operator);
    };
    const loadData = () => {
        dispatch(
            fetchSelectedOperators({
                page: filters.page,
                page_size: filters.pageSize,
                q: filters.searchTerm,
                provider_id: filters.providerId,
            })
        );
    };

    useEffect(() => {
        loadData();
    }, [filters]);

    let buttons: PageTitleProps['buttons'] = [];

    if (!isInProgress && (userPackage.operator_limit === 0 || userPackage.operator_limit > pagination.totalAmount)) {
        buttons = [
            {
                key: 'add-new-operator-page-title-btn',
                component: (
                    <Button onClick={() => setIsCreateNewOperatorDialogOpen(true)} color="primary" className={`c-btn`}>
                        <PlusIcon /> Add new operator
                    </Button>
                ),
            },
        ];
    }
    const tableId = 'operators-container';
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle title={'Operators'} buttons={buttons} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <Search
                                                            search={search}
                                                            placeholder="Search By Operator Name..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <td>#</td>
                                                                    <td>Name</td>
                                                                    <td>URL</td>
                                                                    <td className="text-center">Status</td>
                                                                    <td className="text-end">Actions</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((operator: any, index: number) => (
                                                                    <tr key={`operator-${operator.id}`}>
                                                                        <td>{(selectedPage - 1) * 10 + index + 1}</td>
                                                                        <td>{operator?.name}</td>
                                                                        <td>{operator?.url}</td>
                                                                        <td className="text-center">
                                                                            {operator.status === 'completed' && (
                                                                                <Label color="success">Completed</Label>
                                                                            )}
                                                                            {operator.status === 'pending' && (
                                                                                <Label color="gray">In Progress</Label>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openEditDialog(operator);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Edit
                                                                                </Button>{' '}
                                                                            </>
                                                                            <>
                                                                                {' '}
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openDeleteDialog(operator);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            console.log(pageSize);
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
            </div>
            <CreateNewOperatorDialog
                isOpen={isCreateNewOperatorDialogOpen}
                operator={selectedOperator}
                isEdit={isFromEdit}
                onClose={(refresh = false) => {
                    setIsCreateNewOperatorDialogOpen(false);
                    setSelectedOperator(null);
                    setIsFromEdit(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <DeleteOperatorDialog
                isOpen={isDeleteDialogOpen}
                operator={selectedOperator}
                onClose={(refresh = false) => {
                    setDeleteDialogOpen(false);
                    setSelectedOperator(null);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </>
    );
};
