import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { notFoundProvidersDataSelector } from '../../../selectors/providersSelectors';
import { timeAgo } from '../../../utils/timeAgo';
import { MatchProvidersDialog } from './MatchProvidersDialog';
import { fetchNotFoundProviders } from '../../../slices/providers/thunk';
import { ToastContainer } from 'react-toastify';
import { usePermission } from '../../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';

export const NotFoundProviders = () => {
    document.title = 'Not Found Providers';
    const dispatch: any = useDispatch();
    const canMatchAdminProvider = usePermission(PERMISSIONS.ADMIN_PROVIDERS_MATCH);
    const [isMatchProvidersDialogOpen, setIsMatchProvidersDialogOpen] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<any>(null);
    const { data, isInProgress } = useSelector(notFoundProvidersDataSelector);

    useEffect(() => {
        dispatch(fetchNotFoundProviders());
    }, []);

    const openMatchProvidersDialog = (provider: any): void => {
        setIsMatchProvidersDialogOpen(true);
        setSelectedProvider(provider);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb title={'Not Found Providers'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Providers</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col" />
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Operator</th>
                                                                <th scope="col">Date</th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-end"
                                                                    style={{ width: '150px' }}
                                                                >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((provider: any, index: number) => (
                                                                <tr key={`not-found-provider-${provider.nfp_id}`}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{provider.nfp_name}</td>
                                                                    <td>{provider.operator_name}</td>
                                                                    <td>{timeAgo(provider.nfp_created_at)}</td>
                                                                    <td className="text-end">
                                                                        {canMatchAdminProvider && (
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    openMatchProvidersDialog(provider)
                                                                                }
                                                                            >
                                                                                Match
                                                                            </Button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MatchProvidersDialog
                isOpen={isMatchProvidersDialogOpen}
                provider={selectedProvider}
                onClose={(refresh = false) => {
                    setIsMatchProvidersDialogOpen(false);
                    setSelectedProvider(null);

                    if (refresh) {
                        dispatch(fetchNotFoundProviders());
                    }
                }}
            />
            <ToastContainer />
        </React.Fragment>
    );
};
