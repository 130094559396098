import React from 'react';
import Select from 'react-select';
import { capitalize } from '../../utils/capitalize';
import { UserStatuses } from '../../Services/Api/IUserApiService';

type SelectOperatorStatusProps = {
    setValue: (status: UserStatuses) => void;
    defaultValue?: UserStatuses;
    placeholder: string;
};

const options = [
    { value: UserStatuses.ACTIVE, label: capitalize(UserStatuses.ACTIVE) },
    { value: UserStatuses.INACTIVE, label: capitalize(UserStatuses.INACTIVE) },
];

export const SelectUserStatus = ({
    setValue,
    placeholder = 'Filter By Status...',
    defaultValue,
}: SelectOperatorStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            placeholder={placeholder}
            options={options}
            onChange={(e: { value: UserStatuses; label: string }) => {
                setValue(e.value);
            }}
            value={defaultValue ? options.find((option) => option.value === defaultValue) : undefined}
        />
    );
};
