import React, { useEffect, useMemo, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Input,
    Label,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllJobs, reRunJob, deleteJob, fetchJobsStats } from '../../../slices/jobs/thunk';
import { Pagination } from 'Components/Common/NewPagination';
import { toast, ToastContainer } from 'react-toastify';
import { allJobsDataSelector } from '../../../selectors/jobsSelectors';
import { SelectOperator } from '../../../Components/Selects/SelectOperator';
import { formatDate, formattedDateTime } from '../../../utils/dates';
import { IJob, JOB_STATUSES } from '../../../Services/Api/Job/IJobApiService';
import { SelectJobStatus } from '../../../Components/Selects/SelectJobStatus';
import { get } from 'lodash';
import flags from '../../../common/flags';
import { OperatorScraperTypes, VisibleInOptions } from '../../../Services/Api/IOperatorApiService';
import { SelectVisibleIn } from '../../../Components/Selects/SelectVisibleIn';
import { resetJobsReducerStatus } from '../../../slices/jobs/reducer';
import { SelectOperatorScraperType } from '../../../Components/Selects/SelectOperatorScraperType';
import { JobsChart } from './JobsChart';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { Search } from '../../../Components/Common/Search';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';
import { JobStatuses } from './JobStatuses';
import { timeAgoUTC } from '../../../utils/timeAgo';
import '../../../assets/scss/pages/jobs.scss';
import { usePermission } from '../../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';
import { SelectProvider } from '../../../Components/Selects/SelectProvider';
import { ErrorMessageDialog } from './ErrorMessageDialog';

/* eslint-disable no-restricted-globals */
export const AllJobs = () => {
    document.title = 'Jobs';
    const dispatch: any = useDispatch();

    const { data, isInProgress, isDeleteInProgress, message, success, inProgressJobs, pagination, stats } =
        useSelector(allJobsDataSelector);
    const canDeleteJobs = usePermission(PERMISSIONS.ADMIN_JOBS_DELETE);
    const canReRunJobs = usePermission(PERMISSIONS.ADMIN_JOBS_RERUN);
    const [errorMessageDialog, setErrorMessageDialog] = useState<{
        isOpen: boolean;
        job?: IJob;
    }>({
        isOpen: false,
        job: undefined,
    });
    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 100,
        operatorId: 0,
        providerId: 0,
        status: JOB_STATUSES.ALL,
        originalStatus: JOB_STATUSES.ALL,
        visibleIn: VisibleInOptions.ALL,
        searchTerm: '',
        scraperType: 'all',
        searchInRiskyJob: false,
    });

    const inProgressJobIds = useMemo(() => {
        const res: number[] = [];
        inProgressJobs.forEach((row) => {
            row.jobs.forEach((job) => {
                res.push(job.id);
            });
        });

        return res;
    }, [inProgressJobs]);

    const handleSelect = (operatorId: any) => {
        setFilters({ ...filters, page: 1, operatorId });
    };
    const changeProvider = (providerId: number) => {
        setFilters({ ...filters, page: 1, providerId });
    };
    const changeScraperType = (scraperType: OperatorScraperTypes | 'all') => {
        setFilters({ ...filters, page: 1, scraperType });
    };
    const changeStatus = (status: JOB_STATUSES): void => {
        setFilters({ ...filters, page: 1, status });
    };
    // const changeOriginalStatus = (originalStatus: JOB_STATUSES): void => {
    //     setFilters({ ...filters, page: 1, originalStatus });
    // };
    const changeVisibleIn = (visibleIn: VisibleInOptions): void => {
        setFilters({ ...filters, page: 1, visibleIn });
    };
    const search = (searchTerm: string) => {
        setFilters({ ...filters, page: 1, searchTerm });
    };
    const reRun = (original_id: number): void => {
        dispatch(reRunJob({ original_id }));
    };
    const onDelete = (original_id: number, operator_id: number = 0): void => {
        dispatch(deleteJob(original_id, operator_id));
    };

    const loadData = () => {
        dispatch(
            fetchAllJobs({
                page: filters.page,
                page_size: filters.pageSize,
                operator_id: filters.operatorId,
                provider_id: filters.providerId,
                status: filters.status,
                original_status: filters.originalStatus,
                visible_in: filters.visibleIn,
                q: filters.searchTerm,
                scraper_type: filters.scraperType as 'all' | OperatorScraperTypes,
                search_in_risky_job: filters.searchInRiskyJob,
            })
        );
    };
    useEffect(() => {
        loadData();
    }, [filters]);

    useEffect(() => {
        dispatch(fetchJobsStats());
    }, []);

    useEffect(() => {
        if (!isDeleteInProgress && success !== null) {
            dispatch(resetJobsReducerStatus());
            loadData();

            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            }
        }
    }, [isDeleteInProgress]);

    const tableId = 'all-jobs-container';

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'All Jobs'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <JobsChart data={stats} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {inProgressJobs.length > 0 && (
                                    <>
                                        <PageTitle title={'Jobs In Scraping Phase'} />
                                        <Row>
                                            {inProgressJobs.map((row) => (
                                                <Col lg={6}>
                                                    <Card>
                                                        <CardHeader>
                                                            {row.driver.name}{' '}
                                                            {row.driver.scraper_type === 'pptr' ? (
                                                                <Badge color="success">pptr</Badge>
                                                            ) : row.driver.scraper_type === 'local' ? (
                                                                <Badge color="success">local pptr</Badge>
                                                            ) : (
                                                                <Badge color="warning">wdio</Badge>
                                                            )}
                                                        </CardHeader>
                                                        <CardBody className="p-2">
                                                            <div className="scraping-job-container">
                                                                {row.jobs.map((job) => (
                                                                    <div
                                                                        className="casino-card"
                                                                        key={`in-progress-job-${job.id}`}
                                                                    >
                                                                        <span className="casino-id">{job.id}</span>
                                                                        <span className="casino-name">
                                                                            {job.operator.slug}
                                                                        </span>
                                                                        <span className="time">
                                                                            Created {timeAgoUTC(job.created_at)}
                                                                            {job.created_at !== job.updated_at &&
                                                                                ` (${timeAgoUTC(job.updated_at)})`}
                                                                        </span>
                                                                        {canDeleteJobs && (
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        //eslint-disable-line
                                                                                        confirm(
                                                                                            'Are you sure you want to delete this job'
                                                                                        )
                                                                                    ) {
                                                                                        onDelete(job.id);
                                                                                    }
                                                                                }}
                                                                                className="ms-2"
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <Col lg={12}>
                                        <Card id={tableId}>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={6} sm={2}>
                                                        <Search search={search} placeholder="Search By ID..." />
                                                    </Col>
                                                    <Col xs={6} sm={2}>
                                                        <SelectOperator
                                                            setValue={handleSelect}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operator..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <SelectProvider
                                                            setValue={changeProvider}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter by parsing for provider..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <SelectJobStatus
                                                                    setValue={changeStatus}
                                                                    placeholder="Parsing Status"
                                                                />
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className="form-check form-switch form-switch-secondary mt-2 font-10">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="active-operators-checkbox"
                                                                        checked={filters.searchInRiskyJob}
                                                                        onChange={(e) => {
                                                                            setFilters({
                                                                                ...filters,
                                                                                page: 1,
                                                                                searchInRiskyJob: e.target.checked,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        for="active-operators-checkbox"
                                                                    >
                                                                        Risky Jobs Only
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Col xs={12} sm={2}>*/}
                                                    {/*    <SelectJobStatus*/}
                                                    {/*        setValue={changeOriginalStatus}*/}
                                                    {/*        placeholder="Scrapping Status"*/}
                                                    {/*    />*/}
                                                    {/*</Col>*/}
                                                    <Col xs={6} sm={2}>
                                                        <SelectOperatorScraperType
                                                            setValue={changeScraperType}
                                                            placeholder="Filter By Scraper Type"
                                                            allOption={true}
                                                        />
                                                    </Col>
                                                    <Col xs={6} sm={2}>
                                                        <SelectVisibleIn setValue={changeVisibleIn} />
                                                    </Col>
                                                    <Col sm={12} className="display-flex justify-content-end pt-3">
                                                        <Button
                                                            onClick={() => {
                                                                loadData();
                                                                dispatch(fetchJobsStats());
                                                            }}
                                                        >
                                                            Refresh
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td className="min-w">ID</td>
                                                                    <td scope="col">Operator</td>
                                                                    <td scope="col" className="text-center">
                                                                        Parsing <br /> Scrapping
                                                                    </td>
                                                                    <td scope="col">Providers</td>
                                                                    <td scope="col">Error Message</td>
                                                                    <td scope="col">Games</td>
                                                                    <td scope="col">Json</td>
                                                                    <td scope="col">Date</td>
                                                                    <td scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((job: IJob) => (
                                                                    <tr
                                                                        key={`job-${job.original_id}`}
                                                                        className={job.is_risky ? 'table-warning' : ''}
                                                                    >
                                                                        <td>{job.original_id}</td>
                                                                        <td>
                                                                            <div className="mb-2">
                                                                                <img
                                                                                    src={get(
                                                                                        flags,
                                                                                        `${job.operator.visible_in}.flag`
                                                                                    )}
                                                                                    alt="Skote"
                                                                                    className="me-2 rounded"
                                                                                    height="18"
                                                                                />
                                                                                {job.operator.name}
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                {job.operator.scraper_type ===
                                                                                'pptr' ? (
                                                                                    <Badge color="success">pptr</Badge>
                                                                                ) : job.operator.scraper_type ===
                                                                                  'local' ? (
                                                                                    <Badge color="success">
                                                                                        local pptr
                                                                                    </Badge>
                                                                                ) : (
                                                                                    <Badge color="warning">wdio</Badge>
                                                                                )}{' '}
                                                                                {job.operator.proxy_needed === 1 && (
                                                                                    <Badge color="success">Proxy</Badge>
                                                                                )}{' '}
                                                                                {job.tries > 1 && <>({job.tries})</>}
                                                                            </div>
                                                                            <JobStatuses
                                                                                statuses={job.prev_statuses}
                                                                                jobId={job.original_id}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {job.status === 'in_progress' ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}
                                                                            <br />
                                                                            {job.original_status === 'in_progress' ||
                                                                            inProgressJobIds.includes(
                                                                                job.original_id
                                                                            ) ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.original_status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {job.providers.map((provider: string) => (
                                                                                <div>{provider}</div>
                                                                            ))}
                                                                        </td>
                                                                        <td className="multiline-td">
                                                                            {`${job.message}`.slice(0, 150)}
                                                                            {job.message?.length > 150 && (
                                                                                <>
                                                                                    {' '}
                                                                                    <i
                                                                                        className="ri-information-line icon-link pointer"
                                                                                        onClick={() =>
                                                                                            setErrorMessageDialog({
                                                                                                isOpen: true,
                                                                                                job: job!,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <RatioDisplay
                                                                                type="warning"
                                                                                current={job.games_parsed}
                                                                                total={
                                                                                    job.games_parsed +
                                                                                    job.not_found_games
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <div>
                                                                                <a href={job.info_url} target="_blank">
                                                                                    <i className="icon ri-database-2-line" />
                                                                                </a>
                                                                            </div>
                                                                            {job.error_image && (
                                                                                <div>
                                                                                    <a
                                                                                        href={job.error_image}
                                                                                        target="_blank"
                                                                                    >
                                                                                        <i className="ri-screenshot-2-fill" />
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <Badge color="info">Run at</Badge>{' '}
                                                                                {formattedDateTime(job.created_at)}
                                                                            </div>
                                                                            <div>
                                                                                <Badge color="info">Date</Badge>{' '}
                                                                                {formatDate(new Date(job.date), {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <Row>
                                                                                <Col xs={12}>
                                                                                    {canReRunJobs && (
                                                                                        <Button
                                                                                            color={
                                                                                                job.status !==
                                                                                                'in_progress'
                                                                                                    ? 'warning'
                                                                                                    : 'success'
                                                                                            }
                                                                                            size="sm"
                                                                                            onClick={() =>
                                                                                                reRun(job.original_id)
                                                                                            }
                                                                                        >
                                                                                            Re-run
                                                                                        </Button>
                                                                                    )}
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    {canDeleteJobs && (
                                                                                        <Button
                                                                                            color="danger"
                                                                                            size="sm"
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    //eslint-disable-line
                                                                                                    confirm(
                                                                                                        'Are you sure you want to delete this job'
                                                                                                    )
                                                                                                ) {
                                                                                                    onDelete(
                                                                                                        job.original_id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className="mt-2"
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    )}
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
                <ToastContainer />
                <ErrorMessageDialog
                    isOpen={errorMessageDialog.isOpen}
                    job={errorMessageDialog.job}
                    onClose={() => {
                        setErrorMessageDialog({ isOpen: false, job: undefined });
                    }}
                />
            </div>
        </>
    );
};
