import { IApiPaginationResponse } from '../../slices/types';

export type IOperator = {
    id: number;
    name: string;
    slug?: string;
    url: string;
    status: OperatorStatuses;
    last_succeed_job_id: number;
    visible_in?: string;
    scraper_type?: OperatorScraperTypes;
    proxy_needed?: number;
    headless?: number;
    priority?: number;
    proxy_service?: string;
    is_matched?: number;
};

export type IApiFetchAllOperatorsResponse = {
    code: number;
    data: IOperator[];
    pagination?: IApiPaginationResponse;
};

export enum VisibleInOptions {
    ALL = 'all',
    AM = 'am',
    BE = 'be',
    BG = 'bg',
    BR = 'br',
    AR = 'ar',
    FR = 'fr',
    UK = 'uk',
    GB = 'gb',
    US = 'us',
    IT = 'it',
    GR = 'gr',
    SE = 'se',
    RS = 'rs',
    UA = 'ua',
    TR = 'tr',
    DE = 'de',
    RU = 'ru',
    KZ = 'kz',
    NG = 'ng',
    UZ = 'uz',
    GE = 'ge',
    ES = 'es',
    HR = 'hr',
    IN = 'in',
    AZ = 'az',
    CM = 'cm',
    PH = 'ph',
    ZW = 'zw',
    ZA = 'za',
    CA = 'ca',
    EE = 'ee',
    MX = 'mx',
    PE = 'pe',
    PL = 'pl',
}

export enum OperatorStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum OperatorScraperTypes {
    WDIO = 'wdio',
    PPTR = 'pptr',
    LOCAL = 'local',
}
