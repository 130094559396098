import { createSelector } from 'reselect';
import { ISelectedGamesState } from '../slices/types';

export const selectedGamesStateSelector = (state: any): ISelectedGamesState => state.ClientGames;

export const selectedGamesDataSelector = createSelector(selectedGamesStateSelector, (operators) => ({
    data: operators.selectedGames.data,
    isInProgress: operators.isInProgress.fetchSelectedGames,
    pagination: operators.selectedGames.pagination,
}));

export const createNewGameDataSelector = createSelector(selectedGamesStateSelector, (operators) => ({
    isInProgress: operators.isInProgress.createSelectedGame || operators.isInProgress.editSelectedGame,
    message: operators.message,
    success: operators.success,
}));

export const createDeleteGameDataSelector = createSelector(selectedGamesStateSelector, (operators) => ({
    isInProgress: operators.isInProgress.deleteSelectedGame,
    message: operators.message,
    success: operators.success,
}));