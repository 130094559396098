import { fetchUsersSuccessAction, setUsersInProgressAction, updateUserSuccessAction } from './reducer';
import { setGlobalInProgressAction } from '../global/reducer';
import { UserApiService } from '../../Services/Api/UserApiService';
import { IFetchUsersApiRequestBody, IUpdateUserApiRequestBody } from '../../Services/Api/IUserApiService';

export const fetchUsers = (params: IFetchUsersApiRequestBody) => async (dispatch: any) => {
    dispatch(setUsersInProgressAction('fetchUsers'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await UserApiService.getAll(params);

        if (data.code === 101) {
            dispatch(fetchUsersSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const updateUser = (userId: number, params: IUpdateUserApiRequestBody) => async (dispatch: any) => {
    dispatch(setUsersInProgressAction('updateUser'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await UserApiService.update(userId, params);

        if (data.code === 101) {
            dispatch(updateUserSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
