import { createSelector } from 'reselect';
import { IGamesState } from '../slices/types';

export const gamesStateSelector = (state: any): IGamesState => state.Games;

export const allGamesDataSelector = createSelector(gamesStateSelector, (games) => ({
    data: games.allGames.data,
    isInProgress: games.isInProgress.fetchAllGames,
    pagination: games.allGames.pagination,
}));

export const notFoundGamesDataSelector = createSelector(gamesStateSelector, (games) => ({
    data: games.notFoundGames.data,
    pagination: games.notFoundGames.pagination,
    isInProgress: games.isInProgress.fetchNotFoundGames,
}));

export const matchGamesDataSelector = createSelector(gamesStateSelector, (games) => {
    return {
        success: games.success,
        message: games.message,
        isInProgress: games.isInProgress.matchGames,
        isCreateAndMatchGameInProgress: games.isInProgress.createAndMatchGame,
    };
});

export const attachProviderByURLsDataSelector = createSelector(gamesStateSelector, (games) => {
    return {
        success: games.success,
        message: games.message,
        isInProgress: games.isInProgress.attachProviderByURLs,
    };
});

export const mergeGamesDataSelector = createSelector(gamesStateSelector, (games) => {
    return {
        success: games.success,
        message: games.message,
        isInProgress: games.isInProgress.mergeGames,
    };
});

export const updateOperatorDataSelector = createSelector(gamesStateSelector, (games) => ({
    isInProgress: games.isInProgress.updateGame || games.isInProgress.createGame,
    message: games.message,
    success: games.success,
}));
