export enum PERMISSIONS {
    // Jobs
    ADMIN_JOBS_VIEW = 'admin_jobs.view',
    ADMIN_JOBS_DELETE = 'admin_jobs.delete',
    ADMIN_JOBS_RERUN = 'admin_jobs.rerun',

    // Admin Operators
    ADMIN_OPERATORS_VIEW = 'admin_operators.view',
    ADMIN_OPERATORS_CREATE = 'admin_operators.create',
    ADMIN_OPERATORS_UPDATE = 'admin_operators.update',

    // Admin Providers
    ADMIN_PROVIDERS_VIEW = 'admin_providers.view',
    ADMIN_PROVIDERS_CREATE = 'admin_providers.create',
    ADMIN_PROVIDERS_MERGE = 'admin_providers.merge',
    ADMIN_PROVIDERS_MATCH = 'admin_providers.match',

    // Admin - Games
    ADMIN_GAMES_VIEW = 'admin_games.view',
    ADMIN_GAMES_MERGE = 'admin_games.merge',

    // Admin - general
    ADMIN_NFG_VIEW = 'admin_nfg.view',
    ADMIN_NFP_VIEW = 'admin_nfp.view',
    ADMIN_USERS_VIEW = 'admin_users.view',

    // Account activity
    ACCOUNT_ACTIVITY_VIEW = 'account_activity.view',
    ACCOUNT_ACTIVITY_MONITOR = 'account_activity.monitor',

    // Game Folders
    GAME_FOLDERS = 'game_folders',
}
