import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScores } from '../../../slices/reports/thunk';
import { scoresDataSelector } from '../../../selectors/reportsSelectors';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { ScoresChart } from './ScoresChart';
import { transformScoresToChartData } from '../../../utils/transformScoresToChartData';
import { ScoreCircle } from './ScoreCircle';
import { NoDataTableRow } from '../../../Components/Common/Table/NoDataTableRow';

export const Scores = () => {
    document.title = 'Position Scores';
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const { scores, isInProgress } = useSelector(scoresDataSelector);

    const [selectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);
    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState<number>(0);

    useEffect(() => {
        dispatch(fetchScores(selectedProviderId, selectedGameId, selectedOperatorId));
        setSelectedOperatorIndex(0);
    }, [selectedProviderId, selectedGameId, selectedOperatorId, dispatch]);
    const limitOfTopGames = 5;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Positioning Scores'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedOperator
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {!selectedOperatorId && !selectedProviderId && !selectedGameId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select a provider
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : scores.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-with-manu">
                                                        <div className="menu">
                                                            <div className="title">Operators ({scores.length - 1})</div>
                                                            <div className="description">
                                                                <ul>
                                                                    {scores.map(({ operator }, index) => {
                                                                        return (
                                                                            <li
                                                                                key={`operator-${operator.id}-${selectedProviderId}`}
                                                                                onClick={() => {
                                                                                    setSelectedOperatorIndex(index);
                                                                                }}
                                                                                className={
                                                                                    index === selectedOperatorIndex
                                                                                        ? 'active'
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                {operator.name}
                                                                                <span className="ratio-display">
                                                                                    {operator.score}
                                                                                </span>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="container">
                                                            <div className="title" />
                                                            <div className="description">
                                                                {scores[selectedOperatorIndex]['games'].length > 0 ? (
                                                                    <>
                                                                        <div className="display-flex flex-direction-row align-items-center mt-2 mb-2">
                                                                            <ScoresChart
                                                                                data={transformScoresToChartData(
                                                                                    scores[selectedOperatorIndex],
                                                                                    limitOfTopGames
                                                                                )}
                                                                            />
                                                                            <div className="flex-grow-1 score-games-table">
                                                                                <div className="description">
                                                                                    <div className="label">
                                                                                        Top 5 Games
                                                                                    </div>
                                                                                    <table className="table table-nowrap">
                                                                                        <tbody>
                                                                                            {scores[
                                                                                                selectedOperatorIndex
                                                                                            ]['games']
                                                                                                .slice(
                                                                                                    0,
                                                                                                    limitOfTopGames
                                                                                                )
                                                                                                .map((game, index) => {
                                                                                                    return (
                                                                                                        <tr
                                                                                                            key={`game-info-${scores[selectedOperatorIndex]['operator'].id}-${game.id}`}
                                                                                                        >
                                                                                                            <td className="display-flex align-items-center">
                                                                                                                {index <
                                                                                                                    9 && (
                                                                                                                    <ScoreCircle
                                                                                                                        index={
                                                                                                                            index
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                                <GameRowItem
                                                                                                                    game={
                                                                                                                        game
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <strong>
                                                                                                                    {
                                                                                                                        game.score
                                                                                                                    }
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {scores[selectedOperatorIndex]['games'].length >
                                                                        limitOfTopGames ? (
                                                                            <div className="others-score">
                                                                                <div className="label">
                                                                                    <ScoreCircle
                                                                                        index={limitOfTopGames}
                                                                                    />
                                                                                    Others
                                                                                </div>
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-nowrap">
                                                                                        <thead className="table-light">
                                                                                            <tr>
                                                                                                <td>Game</td>
                                                                                                <td>Score</td>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {scores[
                                                                                                selectedOperatorIndex
                                                                                            ]['games']
                                                                                                .slice(limitOfTopGames)
                                                                                                .map((game, index) => {
                                                                                                    return (
                                                                                                        <tr
                                                                                                            key={`game-info-${scores[selectedOperatorIndex]['operator'].id}-${game.id}`}
                                                                                                        >
                                                                                                            <td className="display-flex align-items-center">
                                                                                                                <GameRowItem
                                                                                                                    game={
                                                                                                                        game
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <strong>
                                                                                                                    {
                                                                                                                        game.score
                                                                                                                    }
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <div className="display-flex flex-direction-row justify-content-center">
                                                                        <NoDataTableRow colSpan={2} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
