import { createSelector } from 'reselect';
import { IJobsState } from '../slices/types';

export const jobsStateSelector = (state: any): IJobsState => state.Jobs;

export const allJobsDataSelector = createSelector(jobsStateSelector, (jobs) => ({
    data: jobs.jobs.data,
    isInProgress: jobs.isInProgress.fetchAllJobs,
    pagination: jobs.jobs.pagination,
    isDeleteInProgress: jobs.isInProgress.deleteJob,
    success: jobs.success,
    message: jobs.message,
    stats: jobs.stats,
    inProgressJobs: jobs.inProgressJobs,
}));
