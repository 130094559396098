import { createSlice } from '@reduxjs/toolkit';
import { IOperatorsState } from '../types';
import { defaultApiPagination } from '../defaultStateValues';

export const initialState: IOperatorsState = {
    allOperators: {
        data: [],
        pagination: defaultApiPagination,
    },
    isInProgress: {
        fetchAllOperators: false,
        createOperator: false,
        updateOperator: false,
    },
    message: '',
    success: null,
};

const operatorsSlice = createSlice({
    name: 'operators',
    initialState,
    reducers: {
        setOperatorsInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchAllOperatorsSuccessAction(state, action) {
            state.allOperators.data = action.payload.data;
            state.allOperators.pagination = action.payload.pagination;
            state.isInProgress.fetchAllOperators = false;
        },
        createOperatorSuccessAction(state, action) {
            state.isInProgress.createOperator = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createOperatorFailedAction(state, action) {
            state.isInProgress.createOperator = false;
            state.message = action.payload.message;
            state.success = false;
        },
        updateOperatorSuccessAction(state, action) {
            state.isInProgress.updateOperator = false;
            state.message = action.payload.message;
            state.success = true;
        },
        updateOperatorFailedAction(state, action) {
            state.isInProgress.updateOperator = false;
            state.message = action.payload.message;
            state.success = false;
        },
        resetOperatorsReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setOperatorsInProgressAction,
    fetchAllOperatorsSuccessAction,
    resetOperatorsReducerStatus,
    createOperatorSuccessAction,
    createOperatorFailedAction,
    updateOperatorSuccessAction,
    updateOperatorFailedAction,
} = operatorsSlice.actions;

export default operatorsSlice.reducer;
