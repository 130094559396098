import {
    Badge,
    Button,
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { createAndMatchGame, matchGames } from '../../../slices/games/thunk';
import { matchGamesDataSelector } from '../../../selectors/gamesSelectors';
import { toast } from 'react-toastify';
import { resetGamesReducerStatus } from '../../../slices/games/reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectProvider } from '../../../Components/Selects/SelectProvider';
import { IJob } from '../../../Services/Api/Job/IJobApiService';

export const ErrorMessageDialog = ({
    isOpen,
    onClose,
    job,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    job?: IJob;
}) => {
    const dispatch: any = useDispatch();
    const [checked, setChecked] = useState(true);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-xl"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    onClose();
                }}
            >
                Error Message
            </ModalHeader>
            <ModalBody>
                <pre>{job?.message}</pre>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};
