import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/NewPagination';
import { Search } from 'Components/Common/Search';
import { UserDialog } from './UserDialog';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { usePermission } from '../../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';
import { fetchUsers } from '../../../slices/users/thunk';
import { usersDataSelector } from '../../../selectors/usersSelectors';
import { IUser } from '../../../Services/Api/IUserApiService';
import { formattedDateTime } from '../../../utils/dates';

export const Users = () => {
    document.title = 'Users';
    const dispatch: any = useDispatch();

    const { data, pagination } = useSelector(usersDataSelector);
    const [isCreateNewOperatorDialogOpen, setIsCreateNewOperatorDialogOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 20,
        searchTerm: '',
    });

    const loadData = () => {
        dispatch(
            fetchUsers({
                page: filters.page,
                page_size: filters.pageSize,
                q: filters.searchTerm,
            })
        );
    };

    useEffect(loadData, [filters, dispatch]);

    const tableId = 'users-table-container';
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle title="Users" />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={3}>
                                                        <Search
                                                            search={(searchTerm) =>
                                                                setFilters({
                                                                    ...filters,
                                                                    page: 1,
                                                                    searchTerm,
                                                                })
                                                            }
                                                            placeholder="Search By User Email, Name etc..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td scope="col" className="min-w">
                                                                        ID
                                                                    </td>
                                                                    <td scope="col">Name</td>
                                                                    <td scope="col">Email</td>
                                                                    <td scope="col">Provider</td>
                                                                    <td scope="col">Status</td>
                                                                    <td scope="col">Last Active Date</td>
                                                                    <td scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((user: IUser) => (
                                                                    <tr key={`operator-${user.id}`}>
                                                                        <td>{user?.id}</td>
                                                                        <td>{user?.name}</td>
                                                                        <td>{user?.email}</td>
                                                                        <td>{user?.provider?.name}</td>
                                                                        <td>
                                                                            {user.status === 'active' ? (
                                                                                <Badge color="success">Active</Badge>
                                                                            ) : (
                                                                                <Badge color="warning">Inactive</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {user?.last_active_date &&
                                                                                formattedDateTime(
                                                                                    user?.last_active_date
                                                                                )}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                color="info"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    setSelectedUser(user);
                                                                                    setIsCreateNewOperatorDialogOpen(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
            </div>
            <UserDialog
                isOpen={isCreateNewOperatorDialogOpen}
                onClose={(refresh = false) => {
                    setIsCreateNewOperatorDialogOpen(false);
                    if (refresh) {
                        loadData();
                    }
                }}
                user={selectedUser}
            />
            <ToastContainer />
        </>
    );
};
