import { BaseApiService } from './BaseApiService';
import { IMergeProvidersApiRequestBody } from '../../slices/types';
import { IProviderApiRequestBody, IMergeProvidersResponse } from './IProviderApiService';

export class ProviderApiService {
    static MERGE_PROVIDERS_URL = '/providers/merge';
    static CREATE_PROVIDERS_URL = '/providers';
    static UPDATE_PROVIDERS_URL = '/providers/{id}';

    static mergeProviders(params: IMergeProvidersApiRequestBody) {
        return BaseApiService.create<IMergeProvidersResponse>(ProviderApiService.MERGE_PROVIDERS_URL, params);
    }

    static create(params: IProviderApiRequestBody) {
        return BaseApiService.create<IMergeProvidersResponse>(ProviderApiService.CREATE_PROVIDERS_URL, params);
    }

    static update(id: number, params: IProviderApiRequestBody) {
        return BaseApiService.update<IMergeProvidersResponse>(
            BaseApiService.fillParams(ProviderApiService.UPDATE_PROVIDERS_URL, { id }),
            params
        );
    }
}
