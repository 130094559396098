import { createSlice } from '@reduxjs/toolkit';
import { IProvidersState } from '../types';

export const initialState: IProvidersState = {
    notFoundProviders: [],
    allProviders: {
        data: [],
        pagination: { totalPages: 0 },
    },
    isInProgress: {
        fetchNotFoundProviders: false,
        createAndMatchProvider: false,
        matchProviders: false,
        fetchAllProviders: false,
        mergeProviders: false,
        createProvider: false,
        updateProvider: false,
    },
    message: '',
    success: null,
};

const providersSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {
        setProvidersInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchNotFoundProvidersSuccessAction(state, action) {
            state.notFoundProviders = action.payload.data;
            state.isInProgress.fetchNotFoundProviders = false;
        },
        createAndMatchProviderSuccessAction(state, action) {
            state.isInProgress.createAndMatchProvider = false;
            state.message = action.payload;
            state.success = true;
        },
        fetchAllProvidersSuccessAction(state, action) {
            state.allProviders.data = action.payload.data;
            state.allProviders.pagination = action.payload.pagination;
            state.isInProgress.fetchAllProviders = false;
        },
        matchProvidersSuccessAction(state, action) {
            state.isInProgress.matchProviders = false;
            state.message = action.payload;
            state.success = true;
        },
        matchProvidersFailedAction(state, action) {
            state.isInProgress.matchProviders = false;
            state.message = action.payload;
            state.success = false;
        },
        createProviderSuccessAction(state, action) {
            state.isInProgress.createProvider = false;
            state.message = action.payload.message;
            state.success = true;
        },
        createProviderFailedAction(state, action) {
            state.isInProgress.createProvider = false;
            state.message = action.payload.message;
            state.success = false;
        },
        updateProviderSuccessAction(state, action) {
            state.isInProgress.updateProvider = false;
            state.message = action.payload.message;
            state.success = true;
        },
        updateProviderFailedAction(state, action) {
            state.isInProgress.updateProvider = false;
            state.message = action.payload.message;
            state.success = false;
        },
        mergeProvidersSuccessAction(
            state,
            {
                payload: { message, success },
            }: {
                payload: { message: string; success: boolean };
            }
        ) {
            state.isInProgress.mergeProviders = false;
            state.message = message;
            state.success = success;
        },
        resetProvidersReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setProvidersInProgressAction,
    fetchNotFoundProvidersSuccessAction,
    fetchAllProvidersSuccessAction,
    createAndMatchProviderSuccessAction,
    matchProvidersSuccessAction,
    matchProvidersFailedAction,
    mergeProvidersSuccessAction,
    resetProvidersReducerStatus,
    createProviderSuccessAction,
    createProviderFailedAction,
    updateProviderSuccessAction,
    updateProviderFailedAction,
} = providersSlice.actions;

export default providersSlice.reducer;
