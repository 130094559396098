import { createSelector } from 'reselect';
import { IUserState } from '../slices/types';

export const usersStateSelector = (state: any): IUserState => state.Users;

export const usersDataSelector = createSelector(usersStateSelector, (users) => ({
    data: users.users.data,
    pagination: users.users.pagination,
}));

export const updateUserSelector = createSelector(usersStateSelector, (users) => ({
    isInProgress: users.isInProgress.updateUser,
    message: users.message,
    success: users.success,
}));
