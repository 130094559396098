class Permission {
    private static instance: Permission;
    private permissions: Set<string>;

    // Private constructor to prevent direct instantiation
    private constructor() {
        this.permissions = new Set<string>();
    }

    // Get the singleton instance
    public static getInstance(): Permission {
        if (!Permission.instance) {
            Permission.instance = new Permission();
        }
        return Permission.instance;
    }

    // Set permissions for the user
    public setPermissions(permissions: string[]): void {
        this.permissions = new Set(permissions);
    }

    // Check if the user has a specific permission
    public hasPermission(permission: string): boolean {
        return this.permissions.has(permission);
    }

    // Check if the user has all permissions (AND logic)
    public hasAllPermissions(permissions: string[]): boolean {
        return permissions.every((perm) => this.permissions.has(perm));
    }

    // Check if the user has any of the permissions (OR logic)
    public hasAnyPermission(permissions: string[]): boolean {
        return permissions.some((perm) => this.permissions.has(perm));
    }

    // Get all permissions
    public getPermissions(): string[] {
        return Array.from(this.permissions);
    }
}

export default Permission;
