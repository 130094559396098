import { OperatorApiService } from '../Services/Api/OperatorApiService';

export const loadOperatorSuggestions =
    (limit = false) =>
    (q: string, callback: (response: any) => void) => {
        OperatorApiService.getAll(limit ? (q.length >= 3 ? { q } : { q: '?' }) : { q }).then((response: any) => {
            return callback(
                response.data.map((item: { name: string; id: number }) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                })
            );
        });
    };
