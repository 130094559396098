import React from 'react';
import { Card } from 'reactstrap';
import { GameIcon } from '../../Layouts/SVG/InfoCard/GameIcon';
import { OperatorIcon } from '../../Layouts/SVG/InfoCard/OperatorIcon';
import { UpIcon } from '../../Layouts/SVG/InfoCard/UpIcon';
import { DownIcon } from '../../Layouts/SVG/InfoCard/DownIcon';
import { Link } from 'react-router-dom';

interface InfoCardProps {
    title?: string;
    description?: string;
    iconType?: 'game' | 'operator';
    iconColor?: 'danger' | 'success' | 'warning';
    amount: number;
    amountType?: 'up' | 'down' | '';
    amountColor?: 'danger' | 'success' | 'warning' | '';
    subAmount?: number;
    url?: string;
}

export const InfoCard = ({
    title,
    description,
    iconType,
    iconColor,
    amount,
    amountType = '',
    amountColor = '',
    subAmount,
    url,
}: InfoCardProps) => {
    let content = (
        <Card className="info-card">
            {title && <div className="title">{title}</div>}
            {description && <div className="description">{description}</div>}
            <div className="icon-container">
                {iconType === 'game' ? <GameIcon color={iconColor} /> : <OperatorIcon color={iconColor} />}
            </div>
            {}
            <div className={`value ${amountType} ${amountColor}`}>
                {amount}
                {amountType !== '' && <>&nbsp;</>}
                {amountType === 'up' ? <UpIcon /> : amountType === 'down' ? <DownIcon /> : <></>}
                <span className="sub-value">
                    {subAmount ? '/' : ''}
                    {subAmount}
                </span>
            </div>
        </Card>
    );
    if (url) {
        content = <Link to={url}>{content}</Link>;
    }
    return content;
};
