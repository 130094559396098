import am from '../assets/images/flags/am.svg';
import br from '../assets/images/flags/br.svg';
import ar from '../assets/images/flags/ar.svg';
import fr from '../assets/images/flags/fr.svg';
import be from '../assets/images/flags/be.svg';
import bg from '../assets/images/flags/bg.svg';
import uk from '../assets/images/flags/gb-eng.svg';
import gb from '../assets/images/flags/gb.svg';
import us from '../assets/images/flags/us.svg';
import it from '../assets/images/flags/it.svg';
import gr from '../assets/images/flags/gr.svg';
import se from '../assets/images/flags/se.svg';
import rs from '../assets/images/flags/rs.svg';
import ua from '../assets/images/flags/ua.svg';
import tr from '../assets/images/flags/tr.svg';
import de from '../assets/images/flags/de.svg';
import ru from '../assets/images/flags/ru.svg';
import kz from '../assets/images/flags/kz.svg';
import ng from '../assets/images/flags/ng.svg';
import az from '../assets/images/flags/az.svg';
import uz from '../assets/images/flags/uz.svg';
import ge from '../assets/images/flags/ge.svg';
import es from '../assets/images/flags/es.svg';
import hr from '../assets/images/flags/hr.svg';
import india from '../assets/images/flags/in.svg';
import cm from '../assets/images/flags/cm.svg';
import ph from '../assets/images/flags/ph.svg';
import zw from '../assets/images/flags/zw.svg';
import za from '../assets/images/flags/za.svg';
import ca from '../assets/images/flags/ca.svg';
import ee from '../assets/images/flags/ee.svg';
import mx from '../assets/images/flags/mx.svg';
import pe from '../assets/images/flags/pe.svg';
import pl from '../assets/images/flags/pl.svg';

const flags = {
    am: {
        label: 'Armenia',
        flag: am,
    },
    pl: {
        label: 'Poland',
        flag: pl,
    },
    pe: {
        label: 'Peru',
        flag: pe,
    },
    br: {
        label: 'Brazil',
        flag: br,
    },
    ar: {
        label: 'Argentina',
        flag: ar,
    },
    fr: {
        label: 'France',
        flag: fr,
    },
    be: {
        label: 'Belgium',
        flag: be,
    },
    bg: {
        label: 'Bulgaria',
        flag: bg,
    },
    uk: {
        label: 'United Kingdom',
        flag: uk,
    },
    gb: {
        label: 'Great Britain',
        flag: gb,
    },
    us: {
        label: 'USA',
        flag: us,
    },
    it: {
        label: 'Italy',
        flag: it,
    },
    gr: {
        label: 'Greece',
        flag: gr,
    },
    se: {
        label: 'Sweden',
        flag: se,
    },
    rs: {
        label: 'Serbia',
        flag: rs,
    },
    ua: {
        label: 'Ukraine',
        flag: ua,
    },
    tr: {
        label: 'Turkiye',
        flag: tr,
    },
    de: {
        label: 'Germany',
        flag: de,
    },
    es: {
        label: 'Spain',
        flag: es,
    },
    ru: {
        label: 'Russia',
        flag: ru,
    },
    kz: {
        label: 'Kazakhstan',
        flag: kz,
    },
    ng: {
        label: 'Nigeria',
        flag: ng,
    },
    uz: {
        label: 'Uzbekistan',
        flag: uz,
    },
    ge: {
        label: 'Georgia',
        flag: ge,
    },
    az: {
        label: 'Azerbaijan',
        flag: az,
    },
    hr: {
        label: 'Croatia',
        flag: hr,
    },
    in: {
        label: 'India',
        flag: india,
    },
    cm: {
        label: 'Cameroon',
        flag: cm,
    },
    ph: {
        label: 'Philippines',
        flag: ph,
    },
    zw: {
        label: 'Zimbabwe',
        flag: zw,
    },
    ca: {
        label: 'Canada',
        flag: ca,
    },
    za: {
        label: 'South Africa',
        flag: za,
    },
    ee: {
        label: 'Estonia',
        flag: ee,
    },
    mx: {
        label: 'Mexico',
        flag: mx,
    },
};

export default flags;
