import { BaseApiService } from '../BaseApiService';
import {
    IAccountDetailsUpdateApiRequestBody,
    IAccountDetailsUpdateDataResponse,
    IAccountPreferenceUpdateApiRequestBody,
    IPasswordUpdateApiRequestBody,
    IUserActivityResponse,
    IUserConfigsResponse,
    IUserRelatedDataResponse,
} from './IAccountApiService';
import { updatePreference } from '../../../slices/auth/account/thunk';
import { IApiResponse } from '../../../helpers/IApiResponse';

export class AccountApiService {
    static FETCH_USER_RELATED_DATA_URL = '/auth/related-data';
    static FETCH_USER_CONFIGS_URL = '/auth/configs';
    static FETCH_USER_ACTIVITY_URL = '/auth/activity';
    static UPDATE_ACCOUNT_DETAILS_URL = '/auth/account-details';
    static UPDATE_PASSWORD_URL = '/auth/password';
    static UPDATE_PREFERENCE_URL = '/auth/preference';

    static fetchUserRelatedData(): Promise<IUserRelatedDataResponse> {
        return BaseApiService.get<IUserRelatedDataResponse>(AccountApiService.FETCH_USER_RELATED_DATA_URL);
    }

    static fetchUserConfigs(): Promise<IUserConfigsResponse> {
        return BaseApiService.get<IUserConfigsResponse>(AccountApiService.FETCH_USER_CONFIGS_URL);
    }

    static fetchUserActivity(): Promise<IUserActivityResponse> {
        return BaseApiService.get<IUserActivityResponse>(AccountApiService.FETCH_USER_ACTIVITY_URL);
    }

    static updatePreference(params: IAccountPreferenceUpdateApiRequestBody): Promise<IApiResponse> {
        return BaseApiService.update<IApiResponse>(AccountApiService.UPDATE_PREFERENCE_URL, params);
    }

    static updateAccountDetails(
        params: IAccountDetailsUpdateApiRequestBody
    ): Promise<IAccountDetailsUpdateDataResponse> {
        return BaseApiService.update<IAccountDetailsUpdateDataResponse>(
            AccountApiService.UPDATE_ACCOUNT_DETAILS_URL,
            params
        );
    }

    static updatePassword(params: IPasswordUpdateApiRequestBody): Promise<IAccountDetailsUpdateDataResponse> {
        return BaseApiService.update<IAccountDetailsUpdateDataResponse>(AccountApiService.UPDATE_PASSWORD_URL, params);
    }
}
