export const formattedDateTime = (value: string) => {
    const date = new Date(value);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateString =
        date.getHours() +
        ':' +
        ('00' + date.getMinutes()).slice(-2) +
        ', ' +
        date.getDate() +
        ' ' +
        monthNames[date.getMonth()];

    return dateString;
};

export function formatDate(date: Date, options?: Intl.DateTimeFormatOptions): string {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    const nowOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (dateOnly.getTime() === nowOnly.getTime()) {
        return 'Today';
    }

    const yesterdayOnly = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
    if (dateOnly.getTime() === yesterdayOnly.getTime()) {
        return 'Yesterday';
    }

    return date.toLocaleDateString(
        'en-US',
        options || {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
        }
    );
}

export function getYesterday(): Date {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
}

export function getYesterdayUTC(): Date {
    const nowUTC = new Date();
    return new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate() - 1, 0, 0, 0));
}
