import React from 'react';
import Select from 'react-select';

type SelectYesNoProps = {
    setValue: (status: number) => void;
    defaultValue: number;
    placeholder: string;
};

const options = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];

export const SelectYesNo = ({ setValue, defaultValue, placeholder = '' }: SelectYesNoProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            options={options}
            onChange={(e: { value: number; label: string }) => {
                setValue(e.value);
            }}
            value={options.find((option) => option.value === defaultValue)}
        />
    );
};
