import { BaseApiService } from './BaseApiService';
import { IReportGamesOnOperatorResponse } from './IReportApiService';
import {
    IAddToFavoritesApiRequestBody,
    IFetchAllGamesResponse,
    IMergeGamesApiRequestBody,
    IMergeGamesResponse,
    IToggleIdentifyGameByImageApiRequestBody,
    IToggleSkipGameApiRequestBody,
} from './IGameApiService';
import {
    IApiCommonResponse,
    IAttachProviderByURLsApiRequestBody,
    ICreateAndMatchGameApiRequestBody,
    IFetchAllGamesApiRequestBody,
    IGameApiRequestBody,
} from '../../slices/types';
import { removeFromFavorites, toggleIgnoreGame } from '../../slices/games/thunk';

export class GameApiService {
    static TOGGLE_IDENTIFY_GAME_BY_IMAGE_URL = '/games/{id}/identify-by-image';
    static TOGGLE_SKIP_GAME_URL = '/games/{id}/skip';
    static TOGGLE_IGNORE_GAME_URL = '/games/{id}/ignore';
    static MERGE_GAMES_URL = '/games/merge';
    static UPDATE_GAME_URL = '/games/{id}';
    static CREATE_GAME_URL = '/games';
    static FETCH_GAMES_URL = '/games';
    static ATTACH_PROVIDER_BY_URLS_URL = '/games/attach-provider';
    static CREATE_AND_MATCH_GAMES_URL = '/games/create-and-match';
    static ADD_TO_FAVORITES = '/game-folders/add';
    static REMOVE_FROM_FAVORITES = '/game-folders/remove';

    static toggleIdentifyGameByImage(params: IToggleIdentifyGameByImageApiRequestBody) {
        return BaseApiService.update<IReportGamesOnOperatorResponse>(
            BaseApiService.fillParams(GameApiService.TOGGLE_IDENTIFY_GAME_BY_IMAGE_URL, { id: params.id }),
            {
                value: params.value ? 1 : 0,
            }
        );
    }

    static toggleSkipGame(params: IToggleSkipGameApiRequestBody) {
        return BaseApiService.update<IReportGamesOnOperatorResponse>(
            BaseApiService.fillParams(GameApiService.TOGGLE_SKIP_GAME_URL, { id: params.id }),
            {
                value: params.value ? 1 : 0,
            }
        );
    }

    static toggleIgnoreGame(params: IToggleSkipGameApiRequestBody) {
        return BaseApiService.update<IReportGamesOnOperatorResponse>(
            BaseApiService.fillParams(GameApiService.TOGGLE_IGNORE_GAME_URL, { id: params.id }),
            {
                value: params.value ? 1 : 0,
            }
        );
    }

    static mergeGames(params: IMergeGamesApiRequestBody) {
        return BaseApiService.create<IMergeGamesResponse>(GameApiService.MERGE_GAMES_URL, params);
    }

    static updateGame(id: number, params: IGameApiRequestBody) {
        return BaseApiService.update<IMergeGamesResponse>(
            BaseApiService.fillParams(GameApiService.UPDATE_GAME_URL, { id }),
            params
        );
    }

    static createGame(params: IGameApiRequestBody) {
        return BaseApiService.create<IMergeGamesResponse>(GameApiService.CREATE_GAME_URL, params);
    }

    static attachProviderByURLs(params: IAttachProviderByURLsApiRequestBody) {
        return BaseApiService.create<IMergeGamesResponse>(GameApiService.ATTACH_PROVIDER_BY_URLS_URL, params);
    }

    static fetchAllGamesApi(params: IFetchAllGamesApiRequestBody) {
        return BaseApiService.get<IFetchAllGamesResponse>(GameApiService.FETCH_GAMES_URL, params);
    }

    static createAndMatchGame(params: ICreateAndMatchGameApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(GameApiService.CREATE_AND_MATCH_GAMES_URL, params);
    }

    static addToFavorites(params: IAddToFavoritesApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(GameApiService.ADD_TO_FAVORITES, params);
    }

    static removeFromFavorites(params: IAddToFavoritesApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(GameApiService.REMOVE_FROM_FAVORITES, params);
    }
}
