import React from 'react';
import Select from 'react-select';
import { capitalize } from '../../utils/capitalize';
import { VisibleInOptions } from '../../Services/Api/IOperatorApiService';
import flags from '../../common/flags';
import { get } from 'lodash';

type SelectVisibleInStatusProps = {
    setValue: (status: VisibleInOptions) => void;
    placeholder?: string;
    withoutAll?: boolean;
    defaultValue?: VisibleInOptions;
};

const options = [
    { value: VisibleInOptions.ALL, label: capitalize(VisibleInOptions.ALL) },
    { value: VisibleInOptions.AM, label: get(flags, `${VisibleInOptions.AM}.label`) },
    { value: VisibleInOptions.AR, label: get(flags, `${VisibleInOptions.AR}.label`) },
    { value: VisibleInOptions.FR, label: get(flags, `${VisibleInOptions.FR}.label`) },
    { value: VisibleInOptions.BR, label: get(flags, `${VisibleInOptions.BR}.label`) },
    { value: VisibleInOptions.BE, label: get(flags, `${VisibleInOptions.BE}.label`) },
    { value: VisibleInOptions.BG, label: get(flags, `${VisibleInOptions.BG}.label`) },
    { value: VisibleInOptions.UK, label: get(flags, `${VisibleInOptions.UK}.label`) },
    { value: VisibleInOptions.GB, label: get(flags, `${VisibleInOptions.GB}.label`) },
    { value: VisibleInOptions.US, label: get(flags, `${VisibleInOptions.US}.label`) },
    { value: VisibleInOptions.IT, label: get(flags, `${VisibleInOptions.IT}.label`) },
    { value: VisibleInOptions.GR, label: get(flags, `${VisibleInOptions.GR}.label`) },
    { value: VisibleInOptions.SE, label: get(flags, `${VisibleInOptions.SE}.label`) },
    { value: VisibleInOptions.RS, label: get(flags, `${VisibleInOptions.RS}.label`) },
    { value: VisibleInOptions.UA, label: get(flags, `${VisibleInOptions.UA}.label`) },
    { value: VisibleInOptions.TR, label: get(flags, `${VisibleInOptions.TR}.label`) },
    { value: VisibleInOptions.ES, label: get(flags, `${VisibleInOptions.ES}.label`) },
    { value: VisibleInOptions.DE, label: get(flags, `${VisibleInOptions.DE}.label`) },
    { value: VisibleInOptions.RU, label: get(flags, `${VisibleInOptions.RU}.label`) },
    { value: VisibleInOptions.KZ, label: get(flags, `${VisibleInOptions.KZ}.label`) },
    { value: VisibleInOptions.NG, label: get(flags, `${VisibleInOptions.NG}.label`) },
    { value: VisibleInOptions.UZ, label: get(flags, `${VisibleInOptions.UZ}.label`) },
    { value: VisibleInOptions.GE, label: get(flags, `${VisibleInOptions.GE}.label`) },
    { value: VisibleInOptions.HR, label: get(flags, `${VisibleInOptions.HR}.label`) },
    { value: VisibleInOptions.IN, label: get(flags, `${VisibleInOptions.IN}.label`) },
    { value: VisibleInOptions.CM, label: get(flags, `${VisibleInOptions.CM}.label`) },
    { value: VisibleInOptions.PH, label: get(flags, `${VisibleInOptions.PH}.label`) },
    { value: VisibleInOptions.AZ, label: get(flags, `${VisibleInOptions.AZ}.label`) },
    { value: VisibleInOptions.ZW, label: get(flags, `${VisibleInOptions.ZW}.label`) },
    { value: VisibleInOptions.ZA, label: get(flags, `${VisibleInOptions.ZA}.label`) },
    { value: VisibleInOptions.EE, label: get(flags, `${VisibleInOptions.EE}.label`) },
    { value: VisibleInOptions.CA, label: get(flags, `${VisibleInOptions.CA}.label`) },
    { value: VisibleInOptions.MX, label: get(flags, `${VisibleInOptions.MX}.label`) },
    { value: VisibleInOptions.PE, label: get(flags, `${VisibleInOptions.PE}.label`) },
    { value: VisibleInOptions.PL, label: get(flags, `${VisibleInOptions.PL}.label`) },
];

export const SelectVisibleIn = ({
    setValue,
    defaultValue,
    placeholder = 'Filter By Location...',
    withoutAll = false,
}: SelectVisibleInStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="visible_in"
            placeholder={placeholder}
            options={!withoutAll ? options : options.filter((option) => option.value !== VisibleInOptions.ALL)}
            onChange={(e: { value: VisibleInOptions; label: string }) => {
                setValue(e.value);
            }}
            value={defaultValue ? options.find((option) => option.value === defaultValue) : undefined}
            formatOptionLabel={(option: { label: string; image?: string; value: VisibleInOptions }) => (
                <div className="hstack gap-3" key={`option-${option.value}`}>
                    {option.value === VisibleInOptions.ALL ? (
                        <span className="flag-placeholder"></span>
                    ) : (
                        <img src={get(flags, `${option.value}.flag`)} alt="Skote" className="rounded" height="18" />
                    )}
                    <div className="p-1 px-2 ">{option.label}</div>
                </div>
            )}
        />
    );
};
