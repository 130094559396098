import { BaseApiService } from './BaseApiService';
import { IApiFetchUsersResponse, IFetchUsersApiRequestBody, IUpdateUserApiRequestBody } from './IUserApiService';
import { IApiCommonResponse } from '../../slices/types';

export class UserApiService {
    static GET_USERS_URL = '/users';
    static UPDATE_USER_URL = '/users/{id}';

    static getAll(params: IFetchUsersApiRequestBody) {
        return BaseApiService.get<IApiFetchUsersResponse>(UserApiService.GET_USERS_URL, params);
    }

    static update(id: number, params: IUpdateUserApiRequestBody) {
        return BaseApiService.update<IApiCommonResponse>(
            BaseApiService.fillParams(UserApiService.UPDATE_USER_URL, { id }),
            params
        );
    }
}
