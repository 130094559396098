import { Button, Form, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './GameDialog.scss';
import { createNewGameDataSelector } from 'selectors/SelectedGamesSelectors';
import { resetSelectedGamesReducerStatus } from 'slices/client-games/reducer';
import { createSelectedGame, editSelectedGame } from 'slices/client-games/thunk';
import { loadClinetGameSuggestions } from 'helpers/loadClientGameSuggestions';
import Select from 'react-select';
import { IFetchAllGamesApiRequestBody } from 'slices/types';

export const CreateNewGameDialog = ({
    isOpen,
    onClose,
    game,
    providerId,
    isEdit,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    game?: any;
    providerId?: number;
    isEdit: boolean;
}) => {
    const dispatch: any = useDispatch();

    const { message, success, isInProgress } = useSelector(createNewGameDataSelector);
    const [gamesData, setGamesData] = useState<any>();
    const [params, setParams] = useState<IFetchAllGamesApiRequestBody>({
        q: '',
        provider_id: 0,
    });

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: game?.name || '',
            type: game ? { value: game?.type, label: game?.type } : null,
            ...(providerId ? { provider_id: providerId } : {}),
            ...(game ? { game_id: game.game_id } : {}),
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter game name'),
        }),
        onSubmit: (values) => {
            isEdit
                ? dispatch(
                      editSelectedGame(game.id, {
                          name: values.name,
                          type: values.type?.label,
                          provider_id: values.provider_id,
                          game_id: values.game_id,
                      })
                  )
                : dispatch(
                      createSelectedGame({
                          name: values.name,
                          type: values.type?.label,
                          provider_id: values.provider_id,
                          game_id: values.game_id,
                      })
                  );
        },
    });

    const handleClose = (refresh = false) => {
        onClose(refresh);
        validation.resetForm();
        setGamesData(null);
    };

    const handleGamesdata = (response: any): void => {
        if (response.length > 0) {
            setGamesData(response);
        } else {
            setGamesData(null);
        }
    };
    const types = [
        { value: 'Slot', label: 'Slot' },
        { value: 'Crash', label: 'Crash' },
        { value: 'Table', label: 'Table' },
        { value: 'Instant/Turbo/Arcade', label: 'Instant/Turbo/Arcade' },
        { value: 'Lottery', label: 'Lottery' },
        { value: 'Bingo', label: 'Bingo' },
        { value: 'Virtual Sports', label: 'Virtual Sports' },
        { value: 'Mines', label: 'Mines' },
        { value: 'Plinko', label: 'Plinko' },
        { value: 'Dice', label: 'Dice' },
        { value: 'Skill', label: 'Skill' },
        { value: 'Unique', label: 'Unique' },
        { value: 'Other', label: 'Other' },
    ];

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedGamesReducerStatus());
                    },
                });

                handleClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetSelectedGamesReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                handleClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-md gago"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && handleClose();
                }}
            >
                {game ? 'Edit the game' : 'Add new game'}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ModalBody>
                    <div className="mb-3">
                        <label htmlFor="gameName" className="form-label">
                            Name *
                        </label>
                        <div className="custom-dropDown-container">
                            <Input
                                type="text"
                                id="gameName"
                                placeholder="Enter name"
                                name="name"
                                value={validation.values.name || ''}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validation.setFieldValue('game_id', 0);
                                    if (e.target.value.length >= 3) {
                                        const _params = { q: e.target.value, provider_id: providerId };
                                        setParams(_params);
                                        loadClinetGameSuggestions()(_params, handleGamesdata);
                                    } else {
                                        setGamesData(null);
                                    }
                                }}
                                onBlur={validation.handleBlur}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                autocomplete="off"
                            />
                            {gamesData ? (
                                <div className="options">
                                    {gamesData.map((game: any) => (
                                        <div
                                            className="option"
                                            onClick={() => {
                                                validation.setFieldValue('name', game.label);
                                                validation.setFieldValue('game_id', game.value);
                                                setGamesData(null);
                                            }}
                                        >
                                            {game?.label}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {validation.touched.name && validation.errors.name ? (
                                <>
                                    <div className="form-control is-invalid visually-hidden" />
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="gameType" className="form-label">
                            Type
                        </label>
                        <Select
                            name="type"
                            id="gameType"
                            isDisabled={isInProgress}
                            value={validation.values.type || ''}
                            multiselect={false}
                            onChange={(e: any) => {
                                validation.setFieldValue('type', e);
                            }}
                            options={types}
                            placeholder="Select Type..."
                            isClearable={true}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => handleClose()} disabled={isInProgress}>
                        {game ? 'Cancel' : 'Close'}
                    </Button>
                    <Button color="primary" type="submit" disabled={isInProgress}>
                        {isInProgress ? (
                            <span className="d-flex align-items-center">
                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                <span className="flex-grow-1 ms-2">Loading...</span>
                            </span>
                        ) : game ? (
                            'Save'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
