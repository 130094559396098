import React, { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';

export interface PageTitleProps {
    title: string;
    buttons?: {
        key: string;
        component: ReactNode;
    }[];
}

export const PageTitle = ({ title, buttons = [] }: PageTitleProps) => {
    return (
        <Row>
            <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{title}</h4>
                    {buttons.map(({ component, key }) => (
                        <React.Fragment key={key}>{component}</React.Fragment>
                    ))}
                </div>
            </Col>
        </Row>
    );
};
