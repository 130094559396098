import { createSlice } from '@reduxjs/toolkit';
import { IGamesState } from '../types';
import { SkipFilterOptions } from '../../Components/Selects/SkipFilterOptions';
import { defaultApiPagination } from '../defaultStateValues'

export const initialState: IGamesState = {
    allGames: {
        data: [],
        pagination: defaultApiPagination,
    },
    notFoundGames: {
        data: [],
        pagination: defaultApiPagination,
    },
    isInProgress: {
        fetchAllGames: false,
        fetchNotFoundGames: false,
        matchGames: false,
        createAndMatchGame: false,
        toggleIdentifyByImage: false,
        toggleSkipGame: false,
        toggleIgnoreGame: false,
        mergeGames: false,
        updateGame: false,
        createGame: false,
        attachProviderByURLs: false,
        addToFavorites: false,
        removeFromFavorites: false,
    },
    filters: {
        fetchNotFoundGames: {
            page: 1,
            q: '',
            provider_id: 0,
            operator_ids: [],
            skip: SkipFilterOptions.ACTIVE,
        },
        fetchAllGames: {
            page: 1,
            q: '',
            provider_id: 0,
        },
    },
    message: '',
    success: null,
};

const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setGamesInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        setGamesFiltersAction(state, action: { payload: { key: keyof typeof initialState.filters; value: any } }) {
            state.filters[action.payload.key] = action.payload.value;
        },
        fetchAllGamesSuccessAction(state, action) {
            state.isInProgress.fetchAllGames = false;
            state.allGames.pagination = action.payload.pagination;
            state.allGames.data = action.payload.data;
        },
        fetchNotFoundGamesSuccessAction(state, action) {
            state.notFoundGames.data = action.payload.data;
            state.notFoundGames.pagination = action.payload.pagination;
            state.isInProgress.fetchNotFoundGames = false;
        },
        createAndMatchGameSuccessAction(state, action) {
            state.isInProgress.createAndMatchGame = false;
            state.message = action.payload;
            state.success = true;
        },
        createAndMatchGameFailedAction(state, action) {
            state.isInProgress.createAndMatchGame = false;
            state.message = action.payload;
            state.success = false;
        },
        matchGamesSuccessAction(state, action) {
            state.isInProgress.matchGames = false;
            state.message = action.payload;
            state.success = true;
        },
        matchGamesFailedAction(state, action) {
            state.isInProgress.matchGames = false;
            state.message = action.payload;
            state.success = false;
        },
        toggleIdentifyByImageSuccessAction(state, action) {
            state.isInProgress.toggleIdentifyByImage = false;
            state.message = action.payload;
            state.success = true;
        },
        toggleSkipGameSuccessAction(state, action) {
            state.isInProgress.toggleSkipGame = false;
            state.message = action.payload;
            state.success = true;
        },
        toggleIgnoreGameSuccessAction(state, action) {
            state.isInProgress.toggleIgnoreGame = false;
            state.message = action.payload;
            state.success = true;
        },
        attachProviderByURLsSuccessAction(state, action) {
            state.isInProgress.attachProviderByURLs = false;
            state.message = action.payload;
            state.success = true;
        },
        mergeGamesSuccessAction(
            state,
            {
                payload: { message, success },
            }: {
                payload: { message: string; success: boolean };
            }
        ) {
            state.isInProgress.mergeGames = false;
            state.message = message;
            state.success = success;
        },
        updateGameSuccessAction(state, action) {
            state.isInProgress.updateGame = false;
            state.message = action.payload.message;
            state.success = action.payload.success;
        },
        createGameSuccessAction(state, action) {
            state.isInProgress.createGame = false;
            state.message = action.payload.message;
            state.success = action.payload.success;
        },
        resetGamesReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setGamesInProgressAction,
    setGamesFiltersAction,
    fetchAllGamesSuccessAction,
    fetchNotFoundGamesSuccessAction,
    matchGamesSuccessAction,
    matchGamesFailedAction,
    resetGamesReducerStatus,
    createAndMatchGameSuccessAction,
    createAndMatchGameFailedAction,
    toggleIdentifyByImageSuccessAction,
    mergeGamesSuccessAction,
    updateGameSuccessAction,
    createGameSuccessAction,
    attachProviderByURLsSuccessAction,
    toggleSkipGameSuccessAction,
    toggleIgnoreGameSuccessAction,
} = gamesSlice.actions;

export default gamesSlice.reducer;
