import { IFetchAllJobsApiRequestBody, IReRunJobApiRequestBody } from '../types';
import {
    deleteJobSuccessAction,
    fetchAllJobsSuccessAction, fetchJobsStatsSuccessAction,
    reRunJobSuccessAction,
    setJobsInProgressAction,
} from './reducer'
import { JobApiService } from '../../Services/Api/Job/JobApiService';

export const fetchAllJobs = (params: IFetchAllJobsApiRequestBody) => async (dispatch: any) => {
    dispatch(setJobsInProgressAction('fetchAllJobs'));
    try {
        const data = await JobApiService.fetchAll(params);

        if (data.code === 101) {
            dispatch(fetchAllJobsSuccessAction(data));
        }
    } catch (error) {}
};

export const fetchJobsStats = () => async (dispatch: any) => {
    dispatch(setJobsInProgressAction('fetchJobsStats'));
    try {
        const data = await JobApiService.fetchJobsStats();

        if (data.code === 101) {
            dispatch(fetchJobsStatsSuccessAction(data));
        }
    } catch (error) {}
};

export const reRunJob = (params: IReRunJobApiRequestBody) => async (dispatch: any) => {
    dispatch(setJobsInProgressAction('reRunJob'));
    try {
        await JobApiService.reRun(params);
        dispatch(reRunJobSuccessAction());
    } catch (error) {}
};

export const deleteJob = (id: number, operator_id?: number) => async (dispatch: any) => {
    dispatch(setJobsInProgressAction('deleteJob'));
    try {
        const data = await JobApiService.delete(id, operator_id);
        dispatch(deleteJobSuccessAction(data));
    } catch (error) {}
};
