import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllGames, toggleIdentifyByImage } from '../../../slices/games/thunk';
import { allGamesDataSelector } from '../../../selectors/gamesSelectors';
import { Pagination } from 'Components/Common/NewPagination';
import { Search } from 'Components/Common/Search';
import { DropDown } from 'Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { ToastContainer } from 'react-toastify';
import { Switch } from '../../../Components/Common/Form/Switch';
import { IGame } from '../../../Services/Api/IReportApiService';
import { MergeGamesDialog } from './MergeGamesDialog';
import { GameDialog } from './GameDialog';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { isNumeric } from '../../../utils/isNumeric';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { usePermission } from '../../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';
import { defaultApiPagination } from '../../../slices/defaultStateValues';

export const AllGames = () => {
    document.title = 'Games';
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const [isMergeGamesDialogOpen, setIsMergeGamesDialogOpen] = useState<boolean>(false);
    const [isGameDialogOpen, setIsGameDialogOpen] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<IGame | undefined>(undefined);
    const { data, isInProgress, pagination } = useSelector(allGamesDataSelector);
    const [filters, setFilters] = useState({
        page: 1,
        pageSize: defaultApiPagination.pageSize,
        searchTerm: '',
        providerId: 0,
    });

    const handleSelect = (providerId: number) => {
        setFilters({ ...filters, page: 1, providerId });
    };
    const changePage = (page: number): void => {
        setFilters({ ...filters, page });
    };

    const search = (query: string) => {
        setFilters({ ...filters, page: 1, searchTerm: query });
    };

    useEffect(() => {
        dispatch(
            fetchAllGames({
                page: filters.page,
                page_size: filters.pageSize,
                q: filters.searchTerm,
                provider_id: filters.providerId,
            })
        );
    }, [filters, dispatch]);

    const canMergeGames = usePermission(PERMISSIONS.ADMIN_GAMES_MERGE);

    const tableId = 'all-games-container';
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle title="Games" />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={4}>
                                                        <Search search={search} placeholder="Search By Game Name..." />
                                                    </Col>
                                                    <Col xs={12} sm={4}>
                                                        <DropDown
                                                            handleSelect={handleSelect}
                                                            isInProgress={isInProgress}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Provider..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={4} className="display-flex justify-content-end">
                                                        <Button
                                                            onClick={() => setIsGameDialogOpen(true)}
                                                            className="c-btn"
                                                        >
                                                            Add new game
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <td scope="col">ID</td>
                                                                    <td scope="col">Name</td>
                                                                    <td scope="col">Aliases</td>
                                                                    <td scope="col">Image</td>
                                                                    <td scope="col">Provider</td>
                                                                    {user.id === 1 && <td scope="col">Creator</td>}
                                                                    <td scope="col">Identify only by image</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((game) => (
                                                                    <tr key={`game-${game?.id}`}>
                                                                        <td>{game?.id}</td>
                                                                        <td>{game?.name}</td>
                                                                        <td>
                                                                            <ul>
                                                                                {game?.aliases
                                                                                    ?.filter(
                                                                                        (alias) => !isNumeric(alias)
                                                                                    )
                                                                                    .map((alias) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={`alias-${game?.id}-${alias}`}
                                                                                            >
                                                                                                {alias}
                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            <img
                                                                                className="img-thumbnail"
                                                                                width={200}
                                                                                src={game?.image}
                                                                            />
                                                                        </td>
                                                                        <td>{game?.provider?.name}</td>
                                                                        {user.id === 1 && (
                                                                            <td scope="col">{game?.creator?.name}</td>
                                                                        )}
                                                                        <td>
                                                                            <Switch
                                                                                defaultValue={game.identify_by_image}
                                                                                onChange={(value) => {
                                                                                    dispatch(
                                                                                        toggleIdentifyByImage({
                                                                                            id: game.id,
                                                                                            value,
                                                                                        })
                                                                                    );
                                                                                }}
                                                                                id={`toggle-identify-by-image-${game.id}`}
                                                                                label="Identify only by image"
                                                                            />
                                                                            {canMergeGames && (
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSelectedGame(game);
                                                                                        setIsMergeGamesDialogOpen(true);
                                                                                    }}
                                                                                    className="c-btn mt-2"
                                                                                    color="primary"
                                                                                >
                                                                                    Merge
                                                                                </Button>
                                                                            )}
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setSelectedGame(game);
                                                                                    setIsGameDialogOpen(true);
                                                                                }}
                                                                                className="c-btn mt-2"
                                                                                color="primary"
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
                <MergeGamesDialog
                    isOpen={isMergeGamesDialogOpen}
                    onClose={(refresh = false) => {
                        setIsMergeGamesDialogOpen(false);
                        setSelectedGame(undefined);

                        if (refresh) {
                            dispatch(fetchAllGames(filters));
                        }
                    }}
                    childGame={selectedGame}
                />
                <GameDialog
                    isOpen={isGameDialogOpen}
                    onClose={() => {
                        setIsGameDialogOpen(false);
                        setSelectedGame(undefined);
                    }}
                    game={selectedGame}
                />
                <ToastContainer />
            </div>
        </>
    );
};
