import React from 'react';
import Select from 'react-select';

type SelectOperatorStatusProps = {
    setValue: (value: string) => void;
    defaultValue: string;
    placeholder: string;
};

export const PROXY_SERVICES = {
    BRD: { short: 'brd', name: 'BrightData' },
    DIMP: { short: 'dimp', name: 'DataImpulse' },
    FROXY: { short: 'froxy', name: 'Froxy' },
};

const options = [
    { value: PROXY_SERVICES.BRD.short, label: PROXY_SERVICES.BRD.name },
    { value: PROXY_SERVICES.DIMP.short, label: PROXY_SERVICES.DIMP.name },
    { value: PROXY_SERVICES.FROXY.short, label: PROXY_SERVICES.FROXY.name },
];

export const SelectOperatorProxyService = ({ setValue, defaultValue, placeholder }: SelectOperatorStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            options={options}
            placeholder={placeholder}
            onChange={(e: { value: string; label: string }) => {
                setValue(e.value);
            }}
            value={options.find((option) => option.value === defaultValue)}
        />
    );
};
