import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allOperatorsDataSelector } from '../../../selectors/operatorsSelectors';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/NewPagination';
import { Search } from 'Components/Common/Search';
import { fetchAllOperators } from 'slices/operators/thunk';
import { OperatorDialog } from './OperatorDialog';
import { get } from 'lodash';
import flags from '../../../common/flags';
import { IOperator } from '../../../Services/Api/IOperatorApiService';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { PROXY_SERVICES } from '../../../Components/Selects/SelectOperatorProxyService';
import { usePermission } from '../../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';
import { defaultApiPagination } from '../../../slices/defaultStateValues';

export const AllOperators = () => {
    document.title = 'Operators';
    const dispatch: any = useDispatch();

    const canCreateAdminOperator = usePermission(PERMISSIONS.ADMIN_OPERATORS_CREATE);
    const canUpdateAdminOperator = usePermission(PERMISSIONS.ADMIN_OPERATORS_UPDATE);
    const { data, pagination } = useSelector(allOperatorsDataSelector);
    const [isCreateNewOperatorDialogOpen, setIsCreateNewOperatorDialogOpen] = useState<boolean>(false);
    const [selectedOperator, setSelectedOperator] = useState<IOperator>();

    const [filters, setFilters] = useState({
        page: 1,
        pageSize: defaultApiPagination.pageSize,
        searchTerm: '',
    });

    const loadData = () => {
        dispatch(
            fetchAllOperators({
                page: filters.page,
                q: filters.searchTerm,
                page_size: filters.pageSize,
            })
        );
    };

    useEffect(loadData, [filters, dispatch]);

    const tableId = 'all-operators-container';
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle title="All Operators" />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Search
                                                            search={(v) =>
                                                                setFilters({
                                                                    ...filters,
                                                                    page: 1,
                                                                    searchTerm: v,
                                                                })
                                                            }
                                                            placeholder="Search By Operator Name..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6} className="display-flex justify-content-end">
                                                        {canCreateAdminOperator && (
                                                            <Button
                                                                onClick={() => setIsCreateNewOperatorDialogOpen(true)}
                                                                className="c-btn"
                                                            >
                                                                Add new operator
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td scope="col" className="min-w">
                                                                        ID
                                                                    </td>
                                                                    <td scope="col">Name</td>
                                                                    <td scope="col">URL</td>
                                                                    <td scope="col">Is Matched</td>
                                                                    <td scope="col">Headless</td>
                                                                    <td scope="col">Priority</td>
                                                                    <td scope="col">Server Location</td>
                                                                    <td scope="col">Scraper Type</td>
                                                                    <td scope="col">Proxy Needed</td>
                                                                    <td scope="col">Status</td>
                                                                    <td scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((operator: any, index: number) => (
                                                                    <tr key={`operator-${operator.id}`}>
                                                                        <td>{operator?.id}</td>
                                                                        <td>{operator?.name}</td>
                                                                        <td>{operator?.url}</td>
                                                                        <td>{operator?.is_matched ? 'Yes' : 'No'}</td>
                                                                        <td>{operator?.headless ? 'Yes' : 'No'}</td>
                                                                        <td>{operator?.priority}</td>
                                                                        <td>
                                                                            <img
                                                                                src={get(
                                                                                    flags,
                                                                                    `${operator.visible_in}.flag`
                                                                                )}
                                                                                alt={operator.visible_in}
                                                                                className="me-2 rounded"
                                                                                height="18"
                                                                            />
                                                                        </td>
                                                                        <td>{operator.scraper_type}</td>
                                                                        <td>
                                                                            {operator.proxy_needed
                                                                                ? /*// @ts-ignore*/
                                                                                  PROXY_SERVICES[
                                                                                      operator.proxy_service.toUpperCase()
                                                                                  ].name
                                                                                : 'No'}{' '}
                                                                        </td>
                                                                        <td>
                                                                            {operator.status === 'active' ? (
                                                                                <Badge color="success">Active</Badge>
                                                                            ) : (
                                                                                <Badge color="warning">Inactive</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {canUpdateAdminOperator && (
                                                                                <Button
                                                                                    color="info"
                                                                                    size="sm"
                                                                                    onClick={() => {
                                                                                        setSelectedOperator(operator);
                                                                                        setIsCreateNewOperatorDialogOpen(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
            </div>
            <OperatorDialog
                isOpen={isCreateNewOperatorDialogOpen}
                onClose={(refresh = false) => {
                    setIsCreateNewOperatorDialogOpen(false);
                    if (refresh) {
                        loadData();
                    }
                }}
                operator={selectedOperator}
            />
            <ToastContainer />
        </>
    );
};
