import {
    setSelectedGamesInProgressAction,
    fetchSelectedGamesSuccessAction,
    createSelectedGameSuccessAction,
    createSelectedGamedFailedAction,
    deleteSelectedGamesSuccessAction,
    editSelectedGamesSuccessAction,
    setClientGamesFiltersAction,
} from './reducer';

import { setGlobalInProgressAction } from '../global/reducer';
import {
    IFetchSelectedGamesApiRequestBody,
    ICreateSelectedGameApiRequestBody,
    IEditSelectedGameApiRequestBody,
    IState,
} from 'slices/types';
import { SelectedGameApiService } from 'Services/Api/SelectedGameApiService';
import { fetchSelectedGamesApi } from 'helpers/backend_helper';
import { fetchUserRelatedData } from '../auth/account/thunk';
import { setGamesFiltersAction } from '../games/reducer';

export const fetchSelectedGames =
    (params: IFetchSelectedGamesApiRequestBody, showInProgress = true) =>
    async (dispatch: any, getState: () => IState) => {
        dispatch(setSelectedGamesInProgressAction('fetchSelectedGames'));
        if (!params.q && showInProgress) {
            dispatch(setGlobalInProgressAction(true));
        }
        try {
            let localFilters = params;
            if (params) {
                dispatch(setClientGamesFiltersAction({ key: 'fetchSelectedGames', value: params }));
            } else {
                localFilters = getState().ClientGames.filters.fetchSelectedGames;
            }

            const data = await fetchSelectedGamesApi(localFilters);

            if (data.code === 101) {
                dispatch(fetchSelectedGamesSuccessAction(data));
            }
        } catch (error) {}
        if (!params.q && showInProgress) {
            dispatch(setGlobalInProgressAction(false));
        }
    };

export const createSelectedGame = (params: ICreateSelectedGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('createSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.createSelectedGame(params);

        if (data.code === 101) {
            dispatch(createSelectedGameSuccessAction(data));
            dispatch(fetchUserRelatedData());
        } else {
            dispatch(createSelectedGamedFailedAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const editSelectedGame = (id: number, params: IEditSelectedGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('editSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.editSelectedGame(id, params);

        if (data.code === 101) {
            dispatch(editSelectedGamesSuccessAction(data));
            dispatch(fetchUserRelatedData());
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const deleteSelectedGame = (id: number) => async (dispatch: any) => {
    dispatch(setSelectedGamesInProgressAction('deleteSelectedGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedGameApiService.deleteSelectedGame(id);

        if (data.code === 101) {
            dispatch(deleteSelectedGamesSuccessAction(data));
            dispatch(fetchUserRelatedData());
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
