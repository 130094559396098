import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/NewPagination';
import { Search } from 'Components/Common/Search';
import { IFetchSelectedGamesApiRequestBody, ISelectedGame } from 'slices/types';
import { CreateNewGameDialog } from './GameDialog';
import { selectedGamesDataSelector } from 'selectors/SelectedGamesSelectors';
import { DeleteGameDialog } from './DeleteGameDialog';
import { useProfile } from 'Components/Hooks/UserHooks';
import { fetchSelectedGames } from 'slices/client-games/thunk';
import { PageTitle, PageTitleProps } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { Label } from '../../../Components/Common/Label';
import { userRelatedDataSelector } from '../../../selectors/accountSelectors';
import { transformSelectedGameIntoGame } from '../../../utils/transformSelectedGameIntoGame';
import { defaultApiPagination } from '../../../slices/defaultStateValues';

export const Games = () => {
    document.title = 'Games';
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const [isCreateNewGameDialogOpen, setIsCreateNewGameDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isFromEdit, setIsFromEdit] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<ISelectedGame | null>(null);
    const { data, isInProgress, pagination } = useSelector(selectedGamesDataSelector);
    const { package: userPackage } = useSelector(userRelatedDataSelector);

    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 100,
        searchTerm: '',
        providerId: user?.isAdmin ? 0 : user?.providerId,
    });

    const search = (searchTerm: string) => {
        setFilters({ ...filters, page: 1, searchTerm });
    };

    const openEditDialog = (game: any): void => {
        setIsFromEdit(true);
        setIsCreateNewGameDialogOpen(true);
        setSelectedGame(game);
    };
    const openDeleteDialog = (game: any): void => {
        setDeleteDialogOpen(true);
        setSelectedGame(game);
    };
    const loadData = () => {
        dispatch(
            fetchSelectedGames({
                page: filters.page,
                page_size: filters.pageSize,
                q: filters.searchTerm,
                provider_id: filters.providerId,
            })
        );
    };

    useEffect(() => {
        loadData();
    }, [filters]);

    let buttons: PageTitleProps['buttons'] = [];

    if (!isInProgress && (userPackage.game_limit === 0 || userPackage.game_limit > pagination.totalAmount)) {
        buttons = [
            {
                key: 'add-new-game-page-title-btn',
                component: (
                    <Button
                        onClick={() => setIsCreateNewGameDialogOpen(true)}
                        color="primary"
                        className={`c-btn ${filters.providerId ? '' : 'disabled'}`}
                    >
                        <PlusIcon /> Add new game
                    </Button>
                ),
            },
        ];
    }

    const tableId = 'games-container';
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle title={'Games'} buttons={buttons} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={3}>
                                                        <Search search={search} placeholder="Search By Game Name..." />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>Type</td>
                                                                    <td className="text-center">Status</td>
                                                                    <td className="text-end">Actions</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((game: ISelectedGame) => (
                                                                    <tr key={`game-${game.id}`}>
                                                                        <td>
                                                                            <GameRowItem
                                                                                game={transformSelectedGameIntoGame(
                                                                                    game
                                                                                )}
                                                                                hideType={true}
                                                                            />
                                                                        </td>
                                                                        <td>{game?.type}</td>
                                                                        <td className="text-center">
                                                                            {game.status === 'completed' && (
                                                                                <Label color="success">Completed</Label>
                                                                            )}
                                                                            {game.status === 'pending' && (
                                                                                <Label color="gray">In Progress</Label>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openEditDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Edit
                                                                                </Button>{' '}
                                                                            </>
                                                                            <>
                                                                                {' '}
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openDeleteDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            console.log(pageSize);
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
            </div>
            <CreateNewGameDialog
                isOpen={isCreateNewGameDialogOpen}
                game={selectedGame}
                providerId={filters.providerId}
                isEdit={isFromEdit}
                onClose={(refresh = false) => {
                    setIsCreateNewGameDialogOpen(false);
                    setSelectedGame(null);
                    setIsFromEdit(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <DeleteGameDialog
                isOpen={isDeleteDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setDeleteDialogOpen(false);
                    setSelectedGame(null);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </>
    );
};
