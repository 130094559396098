import {
    Button,
    Col,
    Form,
    FormFeedback,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createOperator, updateOperator } from '../../../slices/operators/thunk';
import { createNewOperatorDataSelector } from '../../../selectors/operatorsSelectors';
import { resetOperatorsReducerStatus } from '../../../slices/operators/reducer';
import { SelectJobStatus } from '../../../Components/Selects/SelectOperatorStatus';
import {
    IOperator,
    OperatorScraperTypes,
    OperatorStatuses,
    VisibleInOptions,
} from '../../../Services/Api/IOperatorApiService';
import { SelectVisibleIn } from '../../../Components/Selects/SelectVisibleIn';
import { SelectOperatorScraperType } from '../../../Components/Selects/SelectOperatorScraperType';
import { SelectOperatorProxyService } from '../../../Components/Selects/SelectOperatorProxyService';
import { SelectYesNo } from '../../../Components/Selects/SelectYesNo';

export const OperatorDialog = ({
    isOpen,
    onClose,
    operator,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    operator?: IOperator;
}) => {
    const dispatch: any = useDispatch();

    const { message, success, isInProgress } = useSelector(createNewOperatorDataSelector);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: operator?.name ?? '',
            slug: operator?.slug ?? '',
            url: operator?.url ?? '',
            status: operator?.status ?? OperatorStatuses.ACTIVE,
            visible_in: operator?.visible_in ?? VisibleInOptions.AM,
            scraper_type: operator?.scraper_type ?? OperatorScraperTypes.PPTR,
            headless: operator?.headless ?? 1,
            priority: operator?.priority ?? 5,
            proxy_needed: operator?.proxy_needed ?? 0,
            proxy_service: operator?.proxy_service ?? 'brd',
            is_matched: operator?.is_matched ?? 0,
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            slug: Yup.string().required(),
            url: Yup.string().required(),
            status: Yup.string().required(),
            visible_in: Yup.string().required(),
            priority: Yup.string().required(),
            headless: Yup.string().required(),
            proxy_needed: Yup.string().required(),
            is_matched: Yup.string().required(),
        }),
        onSubmit: (values) => {
            if (!!operator) {
                dispatch(updateOperator(operator.id, values));
            } else {
                dispatch(createOperator(values));
            }
        },
    });

    useEffect(() => {
        if (!isInProgress && success !== null) {
            dispatch(resetOperatorsReducerStatus());
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });

                onClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });
            }
        }
    }, [isInProgress]);

    useEffect(() => {
        validation.resetForm();
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-lg"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                {operator ? 'Edit operator' : 'Add new operator'}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ModalBody>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorName" className="form-label">
                                    Name *
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="operatorName"
                                    placeholder="Enter name"
                                    name="name"
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={!!(validation.touched.name && validation.errors.name)}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorSlug" className="form-label">
                                    Slug *
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="operatorSlug"
                                    placeholder="Enter slug"
                                    name="slug"
                                    value={validation.values.slug}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={!!(validation.touched.slug && validation.errors.slug)}
                                />
                                {validation.touched.slug && validation.errors.slug ? (
                                    <FormFeedback type="invalid">{validation.errors.slug}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorUrl" className="form-label">
                                    URL *
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="operatorUrl"
                                    placeholder="Enter URL"
                                    name="url"
                                    value={validation.values.url}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={!!(validation.touched.url && validation.errors.url)}
                                />
                                {validation.touched.url && validation.errors.url ? (
                                    <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorSlug" className="form-label">
                                    Status *
                                </label>
                                <SelectJobStatus
                                    setValue={(value) => {
                                        validation.setFieldValue('status', value);
                                    }}
                                    placeholder="Select status"
                                    defaultValue={validation.values.status}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorSlug" className="form-label">
                                    Scrapper Type *
                                </label>
                                <SelectOperatorScraperType
                                    setValue={(value) => {
                                        validation.setFieldValue('scraper_type', value);
                                    }}
                                    placeholder="Select scraper type"
                                    defaultValue={validation.values.scraper_type}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorHeadless" className="form-label">
                                    Headless *
                                </label>
                                <SelectYesNo
                                    setValue={(value) => {
                                        validation.setFieldValue('headless', value);
                                    }}
                                    placeholder="Select proxy needed"
                                    defaultValue={validation.values.headless}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorPriority" className="form-label">
                                    Priority *
                                </label>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="operatorPriority"
                                    placeholder="Enter priority"
                                    name="priority"
                                    value={validation.values.priority}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={!!(validation.touched.priority && validation.errors.priority)}
                                />
                                {validation.touched.priority && validation.errors.priority ? (
                                    <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="isMatched" className="form-label">
                                    Is Operator Matched *
                                </label>
                                <SelectYesNo
                                    setValue={(value) => {
                                        validation.setFieldValue('is_matched', value);
                                    }}
                                    placeholder="Select Is Matched"
                                    defaultValue={validation.values.is_matched}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorSlug" className="form-label">
                                    Proxy Needed *
                                </label>
                                <SelectYesNo
                                    setValue={(value) => {
                                        validation.setFieldValue('proxy_needed', value);
                                    }}
                                    placeholder="Select proxy needed"
                                    defaultValue={validation.values.proxy_needed}
                                />
                            </div>
                        </Col>
                        {!!validation.values.proxy_needed && (
                            <>
                                <Col lg={6} md={12}>
                                    <div className="mb-3">
                                        <label className="form-label">Proxy Service *</label>
                                        <SelectOperatorProxyService
                                            setValue={(value) => {
                                                validation.setFieldValue('proxy_service', value);
                                            }}
                                            placeholder="Select proxy needed"
                                            defaultValue={validation.values.proxy_service}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={12}>
                                    <div className="mb-3">
                                        <label className="form-label">Visible In *</label>
                                        <SelectVisibleIn
                                            setValue={(value) => {
                                                validation.setFieldValue('visible_in', value);
                                            }}
                                            withoutAll={true}
                                            placeholder="Select server country"
                                            defaultValue={validation.values.visible_in as VisibleInOptions}
                                        />
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()} disabled={isInProgress}>
                        Close
                    </Button>
                    <Button color="primary" type="submit" disabled={isInProgress}>
                        {isInProgress ? (
                            <span className="d-flex align-items-center">
                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                <span className="flex-grow-1 ms-2">Loading...</span>
                            </span>
                        ) : operator ? (
                            'Save'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
