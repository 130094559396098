import { createSelector } from 'reselect';
import { IProvidersState } from '../slices/types';

export const providersStateSelector = (state: any): IProvidersState => state.Providers;

export const allProvidersDataSelector = createSelector(providersStateSelector, (providers) => ({
    data: providers.allProviders.data,
    isInProgress: providers.isInProgress.fetchAllProviders,
    totalPages: providers.allProviders.pagination.totalPages,
}));

export const notFoundProvidersDataSelector = createSelector(providersStateSelector, (providers) => ({
    data: providers.notFoundProviders,
    isInProgress: providers.isInProgress.fetchNotFoundProviders,
}));

export const matchProvidersDataSelector = createSelector(providersStateSelector, (providers) => {
    return {
        success: providers.success,
        message: providers.message,
        isInProgress: providers.isInProgress.matchProviders,
        isCreateAndMatchProviderInProgress: providers.isInProgress.createAndMatchProvider,
    };
});

export const mergeProvidersDataSelector = createSelector(providersStateSelector, (providers) => {
    return {
        success: providers.success,
        message: providers.message,
        isInProgress: providers.isInProgress.mergeProviders,
    };
});

export const createNewProviderDataSelector = createSelector(providersStateSelector, (providers) => ({
    isInProgress: providers.isInProgress.createProvider || providers.isInProgress.updateProvider,
    message: providers.message,
    success: providers.success,
}));
