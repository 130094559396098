import { useSelector } from 'react-redux';
import { IState } from '../../slices/types';
import Permission from '../../Services/Permissions/Permission';

const permissionInstance = Permission.getInstance();

export const usePermission = (permission: string): boolean => {
    const reduxPermissions = useSelector((state: IState) => state.Account.configs.permissions);

    if (permissionInstance.getPermissions().length === 0 && reduxPermissions.length > 0) {
        permissionInstance.setPermissions(reduxPermissions);
    }

    return permissionInstance.hasPermission(permission);
};

export const useAllPermissions = (permissions: string[]): boolean => {
    const reduxPermissions = useSelector((state: IState) => state.Account.configs.permissions);

    if (permissionInstance.getPermissions().length === 0 && reduxPermissions.length > 0) {
        permissionInstance.setPermissions(reduxPermissions);
    }

    return permissionInstance.hasAllPermissions(permissions);
};

export const useAnyPermission = (permissions: string[]): boolean => {
    const reduxPermissions = useSelector((state: IState) => state.Account.configs.permissions);

    if (permissionInstance.getPermissions().length === 0 && reduxPermissions.length > 0) {
        permissionInstance.setPermissions(reduxPermissions);
    }

    return permissionInstance.hasAnyPermission(permissions);
};
