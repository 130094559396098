import {
    fetchUserActivitySuccessAction,
    fetchUserConfigsSuccessAction,
    fetchUserRelatedDataSuccessAction,
    setAccountInProgressAction,
    updateAccountDetailsAction,
    updatePasswordAction,
    updatePreferenceSuccessAction,
} from './reducer';
import {
    IAccountDetailsUpdateApiRequestBody,
    IAccountPreferenceUpdateApiRequestBody,
    IPasswordUpdateApiRequestBody,
} from '../../../Services/Api/Account/IAccountApiService';
import { AccountApiService } from '../../../Services/Api/Account/AccountApiService';
import { updateLoggedinUser } from '../../../helpers/api_helper';
import { logoutUser } from '../login/thunk';

export const updateAccountDetails = (params: IAccountDetailsUpdateApiRequestBody) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('updateAccountDetails'));
    try {
        const data = await AccountApiService.updateAccountDetails(params);

        dispatch(updateAccountDetailsAction(data));

        if (data.code === 101) {
            updateLoggedinUser(data.data);
        }
    } catch (error) {}
};

export const updatePassword = (params: IPasswordUpdateApiRequestBody) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('updatePassword'));
    try {
        const data = await AccountApiService.updatePassword(params);

        dispatch(updatePasswordAction(data));
    } catch (error) {}
};

export const fetchUserRelatedData = () => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('fetchUserRelatedData'));
    try {
        const data = await AccountApiService.fetchUserRelatedData();

        if (data.code === 101) {
            dispatch(fetchUserRelatedDataSuccessAction(data.data));
        }
    } catch (error) {}
};

export const fetchUserConfigs = (history?: any) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('fetchUserConfigs'));
    try {
        const data = await AccountApiService.fetchUserConfigs();

        if (data.code === 101) {
            dispatch(fetchUserConfigsSuccessAction(data.data));
        } else if (data.code === 401) {
            dispatch(logoutUser(history));
        }
    } catch (error) {}
};

export const fetchUserActivity = () => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('fetchUserActivity'));
    try {
        const data = await AccountApiService.fetchUserActivity();

        dispatch(fetchUserActivitySuccessAction(data.data));
    } catch (error) {}
};

export const updatePreference = (params: IAccountPreferenceUpdateApiRequestBody) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('updatePreference'));
    try {
        await AccountApiService.updatePreference(params);

        dispatch(fetchUserConfigs());
        dispatch(updatePreferenceSuccessAction());
    } catch (error) {}
};
