import { Input, Label } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { updatePreference } from '../../slices/auth/account/thunk';

type ICheckBoxProps = {
    value: boolean;
    prefKey: string;
};
export const PreferenceCheckBox = ({ value, prefKey }: ICheckBoxProps) => {
    const dispatch: any = useDispatch();
    const [localValue, setLocalValue] = useState<boolean>(value ?? false);

    return (
        <div className="form-check form-switch form-switch-primary mt-3">
            <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={`account-preferences-${prefKey}`}
                checked={localValue}
                onChange={(e) => {
                    setLocalValue(e.target.checked);
                    dispatch(updatePreference({ key: prefKey, value: e.target.checked }));
                }}
            />
            <Label className="form-check-label" for={`account-preferences-${prefKey}`}>
                Daily Position Changes
            </Label>
        </div>
    );
};
