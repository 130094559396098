import {
    Button,
    Col,
    Form,
    FormFeedback,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IUser, UserStatuses } from '../../../Services/Api/IUserApiService';
import { SelectUserStatus } from '../../../Components/Selects/SelectUserStatus';
import { updateUser } from '../../../slices/users/thunk';
import { updateUserSelector } from '../../../selectors/usersSelectors';
import { resetUsersReducerStatus } from '../../../slices/users/reducer';

export const UserDialog = ({
    isOpen,
    onClose,
    user,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    user?: IUser;
}) => {
    const dispatch: any = useDispatch();

    const { isInProgress, message, success } = useSelector(updateUserSelector);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: user?.name ?? '',
            status: user?.status ? user?.status : UserStatuses.ACTIVE,
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            status: Yup.string().required(),
        }),
        onSubmit: (values) => {
            if (!!user) {
                dispatch(updateUser(user.id, values));
            }
        },
    });

    useEffect(() => {
        if (!isInProgress && success !== null) {
            dispatch(resetUsersReducerStatus());
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });

                onClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });
            }
        }
    }, [isInProgress]);

    useEffect(() => {
        validation.resetForm();
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-lg"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                {user ? 'Edit user' : 'Add new user'}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ModalBody>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorName" className="form-label">
                                    Name *
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="operatorName"
                                    placeholder="Enter name"
                                    name="name"
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={!!(validation.touched.name && validation.errors.name)}
                                    readOnly={true}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="mb-3">
                                <label htmlFor="operatorSlug" className="form-label">
                                    Status *
                                </label>
                                <SelectUserStatus
                                    setValue={(value) => {
                                        validation.setFieldValue('status', value);
                                    }}
                                    placeholder="Select status"
                                    defaultValue={validation.values.status}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()} disabled={isInProgress}>
                        Close
                    </Button>
                    <Button color="primary" type="submit" disabled={isInProgress}>
                        {isInProgress ? (
                            <span className="d-flex align-items-center">
                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                <span className="flex-grow-1 ms-2">Loading...</span>
                            </span>
                        ) : user ? (
                            'Save'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
