import { IApiPaginationResponse, IProvider } from '../../slices/types';

export type IFetchUsersApiRequestBody = {
    page?: number;
    q?: string;
    provider_id?: number;
    page_size?: number;
    status?: 'inactive' | 'active' | null;
};

export type IUpdateUserApiRequestBody = {
    status: UserStatuses;
};

export enum UserStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type IUser = {
    id: number;
    name: string;
    email: string;
    provider: IProvider;
    status: UserStatuses;
    last_active_date: string;
};

export type IApiFetchUsersResponse = {
    code: number;
    data: IUser[];
    pagination?: IApiPaginationResponse;
};
