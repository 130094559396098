import { IPrevJobStatus, JOB_STATUSES } from '../../../Services/Api/Job/IJobApiService';
import { UncontrolledTooltip } from 'reactstrap';
import React from 'react';

export const JobStatuses = ({ statuses, jobId }: { statuses: IPrevJobStatus[]; jobId: number }) => {
    return (
        <>
            <div className="job-statuses" id={`game-statuses-${jobId}`}>
                {statuses.map(({ status, games_parsed, id }, index) => {
                    return (
                        <div
                            key={`job-status-${index}-${id}`}
                            className={`job-status ${status === JOB_STATUSES.DONE ? 'bg-success' : 'bg-danger'}`}
                        ></div>
                    );
                })}
            </div>
            <UncontrolledTooltip target={`game-statuses-${jobId}`} placement="top">
                {statuses.map(({ games_parsed }) => games_parsed).join(', ')}
            </UncontrolledTooltip>
        </>
    );
};
