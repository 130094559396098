import { IGame } from '../../../Services/Api/IReportApiService';
import { useDispatch } from 'react-redux';
import { addToFavorites, removeFromFavorites } from '../../../slices/games/thunk';
import { usePermission } from '../../Hooks/usePermissions';
import { PERMISSIONS } from '../../../Services/Permissions/Permissions';
import { useState } from 'react';

export const GameRowItem = ({ game, hideType = false }: { game: IGame; hideType?: boolean }) => {
    const dispatch: any = useDispatch();
    const canUseGameFoldersActivity = usePermission(PERMISSIONS.GAME_FOLDERS);
    const [isFavorite, setIsFavorite] = useState<boolean>(!!game.folder_id);

    const toggle = () => {
        if (isFavorite) {
            dispatch(removeFromFavorites({ game_id: game.id, folder_id: game.folder_id || 0 }));
        } else {
            dispatch(addToFavorites({ game_id: game.id, folder_id: game.folder_id || 0 }));
        }
        setIsFavorite(!isFavorite);
    };

    return (
        <div className="game-row-item">
            {canUseGameFoldersActivity && (
                <span className="favourite-container" onClick={toggle}>
                    {isFavorite ? <i className="ri-star-fill" /> : <i className="ri-star-line" />}
                </span>
            )}
            {game.image ? <img src={game.image} alt={game.name} /> : <></>}
            <div className={`info ${hideType ? 'justify-content-center' : ''}`}>
                <span className="name">{game.name}</span>
                {!hideType && <span className="type">{game.type}</span>}
            </div>
        </div>
    );
};
