import { createSlice } from '@reduxjs/toolkit';
import { IUserState } from '../types';

export const initialState: IUserState = {
    users: {
        data: [],
        pagination: {
            currentPage: 0,
            pageSize: 10,
            totalAmount: 0,
            totalPages: 0,
        },
    },
    isInProgress: {
        fetchUsers: false,
        updateUser: false,
    },
    message: '',
    success: null,
};

const operatorsSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsersInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchUsersSuccessAction(state, action) {
            state.users.data = action.payload.data;
            state.users.pagination = action.payload.pagination;
            state.isInProgress.fetchUsers = false;
        },
        updateUserSuccessAction(state, action) {
            state.message = action.payload.message;
            state.success = true;
            state.isInProgress.updateUser = false;
        },
        resetUsersReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const { updateUserSuccessAction, setUsersInProgressAction, fetchUsersSuccessAction, resetUsersReducerStatus } =
    operatorsSlice.actions;

export default operatorsSlice.reducer;
