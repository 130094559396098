import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../Components/Hooks/UserHooks';
import { DashboardIcon } from './SVG/Menu/DashboardIcon';
import { AnalyticsIcon } from './SVG/Menu/AnalyticsIcon';
import { OperatorsIcon } from './SVG/Menu/OperatorsIcon';
import { GamesIcon } from './SVG/Menu/GamesIcon';
import { PERMISSIONS } from '../Services/Permissions/Permissions';
import { usePermission } from '../Components/Hooks/usePermissions';

const Navdata = () => {
    const history = useNavigate();
    const { user } = useProfile();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isAnalytics, setIsAnalytics] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute('sub-items')) {
            const ul: any = document.getElementById('two-column-menu');
            const iconItems: any = ul.querySelectorAll('.nav-icon.active');
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove('active');
                var id = item.getAttribute('sub-items');
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove('show');
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (isCurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
    }, [history, isCurrentState, isDashboard]);

    const canViewAdminGames = usePermission(PERMISSIONS.ADMIN_GAMES_VIEW);
    const canViewNFG = usePermission(PERMISSIONS.ADMIN_NFG_VIEW);
    const canViewProviders = usePermission(PERMISSIONS.ADMIN_PROVIDERS_VIEW);
    const canViewNFP = usePermission(PERMISSIONS.ADMIN_NFP_VIEW);
    const canViewAdminOperators = usePermission(PERMISSIONS.ADMIN_OPERATORS_VIEW);
    const canViewJobs = usePermission(PERMISSIONS.ADMIN_JOBS_VIEW);
    const canViewUsers = usePermission(PERMISSIONS.ADMIN_USERS_VIEW);
    const menuItems: any = [];
    let otherMenuItems: any = [];
    if (user?.isAdmin) {
        otherMenuItems = [];

        if (canViewAdminGames) {
            otherMenuItems.push({
                id: 'all-games',
                label: 'Games',
                icon: <GamesIcon />,
                link: '/games/all',
                stateVariables: isCurrentState === 'AllGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllGames');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewNFG) {
            otherMenuItems.push({
                id: 'not-found-games',
                label: 'Not Found Games',
                icon: <GamesIcon />,
                link: '/games/not-found',
                stateVariables: isCurrentState === 'NotFoundGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundGames');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewNFP) {
            otherMenuItems.push({
                id: 'not-found-providers',
                label: 'Not Found Providers',
                icon: <OperatorsIcon />,
                link: '/providers/not-found',
                stateVariables: isCurrentState === 'NotFoundProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundProviders');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewProviders) {
            otherMenuItems.push({
                id: 'all-providers',
                label: 'Providers',
                icon: <OperatorsIcon />,
                link: '/providers/all',
                stateVariables: isCurrentState === 'AllProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllProviders');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewAdminOperators) {
            otherMenuItems.push({
                id: 'all-operators',
                label: 'Operators',
                icon: <OperatorsIcon />,
                link: '/operators/all',
                stateVariables: isCurrentState === 'AllOperators',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllOperators');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewJobs) {
            otherMenuItems.push({
                id: 'all-jobs',
                label: 'Jobs',
                icon: <DashboardIcon />,
                link: '/jobs/all',
                stateVariables: isCurrentState === 'AllJobs',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllJobs');
                    updateIconSidebar(e);
                },
            });
        }

        if (canViewUsers) {
            otherMenuItems.push({
                id: 'users',
                label: 'Users',
                icon: <i className="ri-user-2-fill" />,
                link: '/users',
                stateVariables: isCurrentState === 'Users',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('Users');
                    updateIconSidebar(e);
                },
            });
        }
    } else {
        otherMenuItems = [
            {
                id: 'dashboard',
                label: 'Dashboards',
                icon: <DashboardIcon />,
                link: '/dashboard',
                stateVariables: isDashboard,
                click: function (e: any) {
                    e.preventDefault();
                    setIsDashboard(!isDashboard);
                    setIsCurrentState('Dashboard');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'analytics',
                label: 'Analytics',
                icon: <AnalyticsIcon />,
                link: '/#',
                stateVariables: isAnalytics,
                click: function (e: any) {
                    e.preventDefault();
                    setIsAnalytics(!isAnalytics);
                    setIsCurrentState('Analytics');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: 'missing-games',
                        label: 'Missing Games',
                        icon: 'ri-focus-3-line',
                        link: '/reports/missing-games',
                        stateVariables: isCurrentState === 'PortfolioOverview',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('PortfolioOverview');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-games-on-operator',
                        label: 'Operators Overview',
                        icon: 'ri-focus-3-line',
                        link: '/reports/operators-overview',
                        stateVariables: isCurrentState === 'GamesOnOperator',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('GamesOnOperator');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-games-on-sections',
                        label: 'Game Positioning',
                        icon: 'ri-focus-3-line',
                        link: '/reports/game-positioning',
                        stateVariables: isCurrentState === 'GamesOnSections',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('GamesOnSections');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-position-updates',
                        label: 'Position Updates',
                        icon: 'ri-focus-3-line',
                        link: '/reports/position-updates',
                        stateVariables: isCurrentState === 'PositionUpdates',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('PositionUpdates');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-scores',
                        label: 'Positioning Scores',
                        icon: 'ri-focus-3-line',
                        link: '/reports/scores',
                        stateVariables: isCurrentState === 'Scores',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('Scores');
                            updateIconSidebar(e);
                        },
                    },
                ],
            },
            {
                id: 'operators',
                label: 'Operators',
                icon: <OperatorsIcon />,
                link: '/configs/operators',
                stateVariables: isCurrentState === 'Operators',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('Operators');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'games',
                label: 'Games',
                icon: <GamesIcon />,
                link: '/configs/games',
                stateVariables: isCurrentState === 'Games',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('Games');
                    updateIconSidebar(e);
                },
            },
        ];
    }

    for (const menuItem of otherMenuItems) {
        menuItems.push(menuItem);
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
