import { IOperator } from '../IOperatorApiService';
import { IApiPaginationResponse } from '../../../slices/types';

export type IPrevJobStatus = {
    id: number;
    games_parsed: number;
    status: string;
};
export type IJob = {
    original_id: number;
    created_at: string;
    status: string;
    date: string;
    info_url: string;
    error_image: string;
    message: string;
    games_parsed: number;
    not_found_games: number;
    original_status: JOB_STATUSES;
    tries: number;
    prev_statuses: IPrevJobStatus[];
    providers: string[];
    operator: IOperator;
    is_risky?: boolean;
};

export type IJobStat = {
    date: string;
    done: number;
    failed: number;
    in_progress?: number;
};

export type IInProgressJob = {
    driver: {
        name: string;
        scraper_type: string;
    };
    jobs: {
        id: number;
        created_at: string;
        updated_at: string;
        operator: { slug: string; id: number };
    }[];
};

export type IFetchAllJobsApiResponse = {
    code: number;
    data: {
        data: IJob[];
        pagination: IApiPaginationResponse;
    };
};

export enum JOB_STATUSES {
    ALL = 'all',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
    FAILED = 'failed',
}
