import { useSelector } from 'react-redux';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { preferencesSelector } from '../../selectors/accountSelectors';
import { PREFERENCES } from '../../Services/Preferences/Preferences';
import { PreferenceCheckBox } from './PreferenceCheckBox';

import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from '../../utils/Object';

export const PreferencesTab = () => {
    const { preferences } = useSelector(preferencesSelector);

    return (
        <Row>
            <Col xs={12} sm={4}>
                <div className="mb-3">
                    <h5 className="text-decoration-underline mb-4 ml-0">Email Notification Preferences:</h5>
                    {!isEmpty(preferences) && (
                        <>
                            <PreferenceCheckBox
                                prefKey={PREFERENCES.NOTIFICATION_EMAIL_POSITION_CHANGE}
                                value={preferences[PREFERENCES.NOTIFICATION_EMAIL_POSITION_CHANGE]}
                            />
                        </>
                    )}
                </div>
            </Col>
        </Row>
    );
};
