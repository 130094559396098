import { BaseApiService } from './BaseApiService';
import {
    IApiCommonResponse,
    IApiPaginationResponse,
    ICreateOperatorApiRequestBody,
    IFetchAllProvidersApiRequestBody,
} from '../../slices/types';
import { IApiFetchAllOperatorsResponse } from './IOperatorApiService';

export class OperatorApiService {
    static GET_ALL_OPERATORS_URL = '/operators';
    static CREATE_OPERATOR_URL = '/operators';
    static UPDATE_OPERATOR_URL = '/operators/';

    static create(params: ICreateOperatorApiRequestBody) {
        return BaseApiService.create<IApiCommonResponse>(OperatorApiService.CREATE_OPERATOR_URL, params);
    }

    static update(id: number, params: ICreateOperatorApiRequestBody) {
        return BaseApiService.update<IApiCommonResponse>(OperatorApiService.UPDATE_OPERATOR_URL + id, params);
    }

    static getAll(params: IFetchAllProvidersApiRequestBody) {
        return BaseApiService.get<IApiFetchAllOperatorsResponse>(OperatorApiService.GET_ALL_OPERATORS_URL, params);
    }
}
