import React, { useCallback } from 'react';
import { Row } from 'reactstrap';
import Select from 'react-select';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalAmount: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (size: number) => void;
    tableId?: string;
}

export const Pagination = ({
    currentPage,
    totalPages,
    pageSize,
    totalAmount,
    onPageChange,
    onPageSizeChange,
    tableId,
}: PaginationProps) => {
    const maxPageNumbers = 5;
    const pageSizes = [10, 20, 50, 100];

    const generatePageNumbers = useCallback(() => {
        if (totalPages <= maxPageNumbers) return Array.from({ length: totalPages }, (_, i) => i + 1);

        const pages: (number | string)[] = [1];

        if (currentPage > 3) pages.push('...');

        let start = Math.max(2, currentPage - 1);
        let end = Math.min(totalPages - 1, currentPage + 1);

        for (let i = start; i <= end; i++) pages.push(i);

        if (currentPage < totalPages - 2) pages.push('...');

        if (!pages.includes(totalPages)) pages.push(totalPages);

        return pages;
    }, [currentPage, totalPages, pageSize, totalAmount]);

    const moveToTheTableTop = () => {
        if (tableId) {
            const targetElement = document.getElementById(tableId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <Row className="g-0 justify-content-end mb-4">
            <div className="col-sm-auto">
                <div className="pagination-container">
                    <div className="pagination-info">
                        <span>Results per page:</span>
                        <Select
                            class="page-size-selector"
                            name="pageSize"
                            options={pageSizes.map((size) => ({ value: size, label: size }))}
                            onChange={(e: { value: number; label: number }) => {
                                onPageSizeChange(e.value);
                                moveToTheTableTop();
                            }}
                            value={{ value: pageSize, label: pageSize }}
                        />
                        <span>{`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, totalAmount)} of ${totalAmount}`}</span>
                    </div>
                    <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                        {currentPage === 1 ? (
                            <li className={'page-item disabled'}>
                                <span className="page-link">
                                    <i className="mdi mdi-chevron-double-left" />
                                </span>
                            </li>
                        ) : (
                            <li className={'page-item'}>
                                <span
                                    className="page-link pointer"
                                    onClick={() => {
                                        onPageChange(1);
                                        moveToTheTableTop();
                                    }}
                                >
                                    <i className="mdi mdi-chevron-double-left" />
                                </span>
                            </li>
                        )}
                        {currentPage <= 1 ? (
                            <li className={'page-item disabled'}>
                                <span className="page-link">
                                    <i className="mdi mdi-chevron-left" />
                                </span>
                            </li>
                        ) : (
                            <li className={currentPage <= 1 ? 'page-item disabled' : 'page-item'}>
                                <span
                                    className="page-link pointer"
                                    onClick={() => {
                                        onPageChange(currentPage - 1);
                                        moveToTheTableTop();
                                    }}
                                >
                                    <i className="mdi mdi-chevron-left" />
                                </span>
                            </li>
                        )}

                        {generatePageNumbers().map((page, index) => {
                            return typeof page === 'number' ? (
                                <>
                                    {page === currentPage ? (
                                        <li className="page-item" key={`page-link-${page}`}>
                                            <span className={'page-link active'}>{page}</span>
                                        </li>
                                    ) : (
                                        <li className="page-item" key={`page-link-${page}`}>
                                            <span
                                                className={'page-link pointer'}
                                                onClick={() => {
                                                    onPageChange(page);
                                                    moveToTheTableTop();
                                                }}
                                            >
                                                {page}
                                            </span>
                                        </li>
                                    )}
                                </>
                            ) : (
                                <li className="page-item" key={`page-link-${index}`}>
                                    <span className={'page-link'}>{page}</span>
                                </li>
                            );
                        })}

                        {currentPage >= totalPages ? (
                            <li className={'page-item disabled'}>
                                <span className="page-link">
                                    <i className="mdi mdi-chevron-right" />
                                </span>
                            </li>
                        ) : (
                            <li className={currentPage >= totalPages ? 'page-item disabled' : 'page-item'}>
                                <span
                                    className="page-link pointer"
                                    onClick={() => {
                                        onPageChange(currentPage + 1);
                                        moveToTheTableTop();
                                    }}
                                >
                                    <i className="mdi mdi-chevron-right" />
                                </span>
                            </li>
                        )}
                        {currentPage === totalPages ? (
                            <li className={'page-item disabled'}>
                                <span className="page-link">
                                    <i className="mdi mdi-chevron-double-right" />
                                </span>
                            </li>
                        ) : (
                            <li className={'page-item'}>
                                <span
                                    className="page-link pointer"
                                    onClick={() => {
                                        onPageChange(totalPages);
                                        moveToTheTableTop();
                                    }}
                                >
                                    <i className="mdi mdi-chevron-double-right" />
                                </span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </Row>
    );
};
