import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotFoundGames, toggleIgnoreGame, toggleSkipGame } from '../../../slices/games/thunk';
import { notFoundGamesDataSelector } from '../../../selectors/gamesSelectors';
import { timeAgo } from '../../../utils/timeAgo';
import { MatchGamesDialog } from './MatchGamesDialog';
import { ToastContainer } from 'react-toastify';
import { Search } from '../../../Components/Common/Search';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { Pagination } from '../../../Components/Common/NewPagination';
import { loadOperatorSuggestions } from '../../../helpers/loadOperatorSuggestions';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { AttachProviderByURLsDialog } from './AttachProviderByURLsDialog';
import { Switch } from '../../../Components/Common/Form/Switch';
import { SelectNFGSkipFilter } from '../../../Components/Selects/SelectNFGSkipFilter';
import { SkipFilterOptions } from '../../../Components/Selects/SkipFilterOptions';

export const NotFoundGames = () => {
    document.title = 'Not Found Games';
    const dispatch: any = useDispatch();
    const [isMatchGamesDialogOpen, setIsMatchGamesDialogOpen] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<any>(null);
    const { data, isInProgress, pagination } = useSelector(notFoundGamesDataSelector);

    const [isAttachProviderByURLsDialogOpen, setIsAttachProviderByURLsDialogOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 10,
        searchTerm: '',
        operatorIds: [],
        isActiveOperatorsSelected: false,
        skip: SkipFilterOptions.ACTIVE,
        activeProviderId: 0,
        providerId: 0,
        onlyMatchedOperatorSelected: false,
    });

    const loadData = (): void => {
        dispatch(
            fetchNotFoundGames({
                page: filters.page,
                page_size: filters.pageSize,
                q: filters.searchTerm,
                provider_id: filters.providerId,
                operator_ids: filters.operatorIds,
                skip: filters.skip,
                search_in_active_operators: filters.isActiveOperatorsSelected,
                active_provider_id: filters.activeProviderId,
                only_matched_operator: filters.onlyMatchedOperatorSelected,
            })
        );
    };

    useEffect(() => {
        loadData();
    }, [filters, dispatch]);

    const openMatchGamesDialog = (game: any): void => {
        setIsMatchGamesDialogOpen(true);
        setSelectedGame(game);
    };

    const tableId = 'not-found-games-container';
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100" id={tableId}>
                                <PageTitle
                                    title={'Not Found Games'}
                                    buttons={[
                                        {
                                            key: 'attach-provider-by-image-page-title-btn',
                                            component: (
                                                <Button
                                                    onClick={() => setIsAttachProviderByURLsDialogOpen(true)}
                                                    color="primary"
                                                    className="c-btn"
                                                >
                                                    <PlusIcon /> Attach provider by Image URLs
                                                </Button>
                                            ),
                                        },
                                    ]}
                                />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={2}>
                                                        <Search
                                                            onlyOnEnter={true}
                                                            search={(searchTerm) => {
                                                                setFilters({ ...filters, page: 1, searchTerm });
                                                            }}
                                                            placeholder="Search By Game Name..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <SelectNFGSkipFilter
                                                            setValue={(skip) => {
                                                                setFilters({ ...filters, page: 1, skip });
                                                            }}
                                                            defaultValue={SkipFilterOptions.ACTIVE}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <DropDown
                                                            handleSelect={(providerId) => {
                                                                setFilters({ ...filters, page: 1, providerId });
                                                            }}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Provider..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <DropDown
                                                            handleSelect={(ids) => {
                                                                setFilters({
                                                                    ...filters,
                                                                    page: 1,
                                                                    operatorIds: ids as any as [],
                                                                });
                                                            }}
                                                            isMulti={true}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadOperatorSuggestions()}
                                                            placeholder="Filter By Operators..."
                                                            hideImage={true}
                                                            value={filters.operatorIds}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <div className="form-check form-switch form-switch-secondary mt-2 font-10">
                                                            <Input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="matched-operators-checkbox"
                                                                checked={filters.onlyMatchedOperatorSelected}
                                                                onChange={(e) => {
                                                                    setFilters({
                                                                        ...filters,
                                                                        page: 1,
                                                                        onlyMatchedOperatorSelected: e.target.checked,
                                                                    });
                                                                }}
                                                            />
                                                            <Label
                                                                className="form-check-label"
                                                                for="matched-operators-checkbox"
                                                            >
                                                                Show only matched operators
                                                            </Label>
                                                        </div>
                                                        <div className="form-check form-switch form-switch-secondary mt-2 font-10">
                                                            <Input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="active-operators-checkbox"
                                                                checked={filters.isActiveOperatorsSelected}
                                                                onChange={(e) => {
                                                                    setFilters({
                                                                        ...filters,
                                                                        page: 1,
                                                                        isActiveOperatorsSelected: e.target.checked,
                                                                    });
                                                                }}
                                                            />
                                                            <Label
                                                                className="form-check-label"
                                                                for="active-operators-checkbox"
                                                            >
                                                                Active Operators Only
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <DropDown
                                                            handleSelect={(activeProviderId) => {
                                                                setFilters({
                                                                    ...filters,
                                                                    page: 1,
                                                                    activeProviderId,
                                                                });
                                                            }}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Customer..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <td scope="col">ID</td>
                                                                <td scope="col" colSpan={2}>
                                                                    Name
                                                                </td>
                                                                <td scope="col">Section</td>
                                                                <td scope="col">Provider</td>
                                                                <td scope="col">Operator</td>
                                                                <td scope="col">Date</td>
                                                                <td scope="col" style={{ width: '150px' }}>
                                                                    Action
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((game: any, index: number) => (
                                                                <tr key={`not-found-game-${game.nfg_id}`}>
                                                                    <td>{game.nfg_id}</td>
                                                                    <td>
                                                                        {game.nfg_name || (
                                                                            <Badge color="warning">No Name</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            className="img-thumbnail"
                                                                            width={200}
                                                                            src={game.nfg_image}
                                                                        />
                                                                    </td>
                                                                    <td>{game.section_name}</td>
                                                                    <td>
                                                                        {game?.provider_name}{' '}
                                                                        {!game?.provider_id && game?.provider_name && (
                                                                            <>
                                                                                {' '}
                                                                                -{' '}
                                                                                <Badge color="danger">Not Found</Badge>
                                                                            </>
                                                                        )}
                                                                        {!game?.provider_id && !game?.provider_name && (
                                                                            <Badge color="warning">No Provider</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>{game?.operator_name}</td>
                                                                    <td>{timeAgo(game.nfg_created_at)}</td>
                                                                    <td className="text-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="mb-2 c-btn"
                                                                            onClick={() => openMatchGamesDialog(game)}
                                                                        >
                                                                            Match
                                                                        </Button>
                                                                        <Switch
                                                                            defaultValue={game.nfg_skip === 1}
                                                                            onChange={(value) => {
                                                                                dispatch(
                                                                                    toggleSkipGame({
                                                                                        id: game.nfg_id,
                                                                                        value,
                                                                                    })
                                                                                );
                                                                            }}
                                                                            id={`toggle-skip-${game.nfg_id}`}
                                                                            label="Skip"
                                                                        />
                                                                        <Switch
                                                                            defaultValue={game.nfg_ignore === 1}
                                                                            onChange={(value) => {
                                                                                dispatch(
                                                                                    toggleIgnoreGame({
                                                                                        id: game.nfg_id,
                                                                                        value,
                                                                                    })
                                                                                );
                                                                            }}
                                                                            id={`toggle-ignore-${game.nfg_id}`}
                                                                            label="Ignore"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        {...pagination}
                        pageSize={filters.pageSize}
                        onPageChange={(page) => {
                            setFilters({
                                ...filters,
                                page,
                            });
                        }}
                        onPageSizeChange={(pageSize) => {
                            setFilters({
                                ...filters,
                                page: 1,
                                pageSize,
                            });
                        }}
                        tableId={tableId}
                    />
                </Container>
            </div>
            <MatchGamesDialog
                isOpen={isMatchGamesDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setIsMatchGamesDialogOpen(false);
                    setSelectedGame(null);

                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <AttachProviderByURLsDialog
                isOpen={isAttachProviderByURLsDialogOpen}
                onClose={(refresh = false) => {
                    setIsAttachProviderByURLsDialogOpen(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </React.Fragment>
    );
};
