import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';
import { useDispatch } from 'react-redux';

import { useProfile } from '../Components/Hooks/UserHooks';

import { logoutUser } from '../slices/auth/login/thunk';
import { fetchUserConfigs, fetchUserRelatedData } from '../slices/auth/account/thunk';

const AuthProtected = (props: any) => {
    const dispatch: any = useDispatch();
    const { user, loading, token } = useProfile();

    const navigate = useNavigate();

    useEffect(() => {
        if (user?.loggedIn && !loading && token) {
            setAuthorization(token);
        } else if (!user?.loggedIn && loading && !token) {
            dispatch(logoutUser(navigate));
        }
    }, [token, user, loading, dispatch]);

    useEffect(() => {
        dispatch(fetchUserRelatedData());
        dispatch(fetchUserConfigs(navigate));
    }, []);

    /*
      Navigate is un-auth access protected routes via url
      */

    if (!user?.loggedIn && loading && !token) {
        return <Navigate to={{ pathname: '/login' }} />;
    }

    return <>{props.children}</>;
};

export default AuthProtected;
