import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { PageTitle } from '../../Components/Common/PageTitle';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUserActivity } from '../../slices/auth/account/thunk';
import { userActivitySelector } from '../../selectors/accountSelectors';
import { usePermission } from '../../Components/Hooks/usePermissions';
import { PERMISSIONS } from '../../Services/Permissions/Permissions';

export const Activity = () => {
    document.title = 'Account Activity';

    const dispatch: any = useDispatch();
    const activity = useSelector(userActivitySelector);
    const canMonitorAccountActivity = usePermission(PERMISSIONS.ACCOUNT_ACTIVITY_MONITOR);

    useEffect(() => {
        dispatch(fetchUserActivity());
    }, []);

    const totalGoalForAWeek = 1000;

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <div className="h-100">
                            <PageTitle title="Account Activity" />
                            <Row>
                                <Col lg={12}>
                                    <div className="stats-table-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Match</th>
                                                    <th>Create & Match</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activity.map((week) => {
                                                    return (
                                                        <tr key={`${week.week_start}-${week.week_end}-${week.user.id}`}>
                                                            <td>
                                                                {week.week_start} - {week.week_end}
                                                            </td>
                                                            <td>{week.user.name}</td>
                                                            <td>{week.stats.match}</td>
                                                            <td>{week.stats.create_and_match}</td>
                                                            <td className="highlight-total">
                                                                {week.stats.match + week.stats.create_and_match}
                                                                <div className="progress-bar-container">
                                                                    <div
                                                                        className="progress-bar"
                                                                        style={{
                                                                            width: `${Math.min(((week.stats.match + week.stats.create_and_match) / totalGoalForAWeek) * 100, 100)}%`,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    );
};
