import { createSlice } from '@reduxjs/toolkit';
import { IReportsState } from '../types';

export const initialState: IReportsState = {
    gamesOnOperator: {
        sections: [],
    },
    portfolio: {
        operators: [],
    },
    scores: [],
    gamesOnSections: {
        operators: [],
    },
    positionUpdates: {
        operators: [],
    },
    positionChangeStats: [],
    isInProgress: {
        fetchGamesOnOperator: false,
        fetchPortfolio: false,
        fetchGamesOnSections: false,
        fetchPositionChangeStats: false,
        positionUpdates: false,
        fetchScores: false,
    },
    message: '',
    success: null,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReportsInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchGamesOnOperatorSuccessAction(state, action) {
            state.gamesOnOperator = action.payload.data;
            state.isInProgress.fetchGamesOnOperator = false;
        },
        fetchPortfolioSuccessAction(state, action) {
            state.portfolio.operators = action.payload.data;
            state.isInProgress.fetchPortfolio = false;
        },
        fetchScoresSuccessAction(state, action) {
            state.scores = action.payload.data;
            state.isInProgress.fetchScores = false;
        },
        fetchGamesOnSectionsSuccessAction(state, action) {
            state.gamesOnSections.operators = action.payload.data;
            state.isInProgress.fetchGamesOnSections = false;
        },
        resetGamesOnSectionsAction(state) {
            state.gamesOnSections.operators = [];
        },
        fetchPositionUpdatesSuccessAction(state, action) {
            state.positionUpdates.operators = action.payload.data;
            state.isInProgress.positionUpdates = false;
        },
        resetReportsReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
        resetPositionUpdatesAction(state) {
            state.positionUpdates.operators = [];
        },
        fetchPositionChangeStatsSuccessAction(state, action) {
            state.positionChangeStats = action.payload.data;
            state.isInProgress.fetchPositionChangeStats = false;
        },
    },
});

export const {
    setReportsInProgressAction,
    fetchGamesOnOperatorSuccessAction,
    fetchPortfolioSuccessAction,
    fetchScoresSuccessAction,
    fetchGamesOnSectionsSuccessAction,
    fetchPositionUpdatesSuccessAction,
    resetGamesOnSectionsAction,
    resetPositionUpdatesAction,
    fetchPositionChangeStatsSuccessAction,
} = reportsSlice.actions;

export default reportsSlice.reducer;
